import router from "../router";
import store from '../store';
const {requestFetch} = require('../store/config')

export default {
    namespaced: true,
    state: {
        dark_theme:false
    },
    mutations: {
        setDarkMode (state, dark_theme){
            state.dark_theme = dark_theme;
        },
    },
    actions: {

        //CREATE USER
        // async registerUser({ commit }, user) {
        //     commit('setErrors', 'clear');
        //     const response = await requestFetch('/api/auth/register',user);
        //     if(response.error) commit('setErrors', response.error);
        //     return response;
        // },

        // GET USERS
        async setThemeColor({ commit }, data) {
            commit('setDarkMode', data);
        },

    },
    getters: {
        getDark(state){
            return state.dark_theme;
        }
    }

}
