<template>
  <v-container fluid >
    <v-row class="d-flex justify-center  pa-8 ml-2">
      <v-col cols="12">
        <h1 style="text-align: center">Llene la Información</h1>
      </v-col>
    </v-row>
    <v-form   @submit.prevent="updateFiles(company)">
      <v-row class="d-flex pa-8 ml-2 mr-2 layout-form" >
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <h2 style="text-align: center">Key</h2>
          <v-file-input
              truncate-length="15"
              id="mykey"
              clear-icon=""
              counter
              show-size
              @change="handleImage('key')"
          ></v-file-input>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <h2 style="text-align: center">Cer</h2>
          <v-file-input
              clear-icon=""
              truncate-length="15"
              counter
              show-size
              id="mycer"
              @change="handleImage('cer')"
          ></v-file-input>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="12"
        >
          <v-text-field
              label="Sat Contraseña"
              placeholder="Sat Contraseña"
              v-model="company.sat_password"
              color="input_dashboard"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="12"
        >
          <v-btn
              class="pa-6"
              block
              style="color: white"
              color="primary"
              type="submit"
          >
            Guardar Datos
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <Overlay :overlay="overlay"/>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import Overlay from "../General/Overlay";
export default {
  name: "FormFilesCompany",
  data(){
    return{
      overlay:false,
      company:{
        file_key:'',
        file_cert:'',
        sat_password:'',
      },
    }
  },
  components: { Overlay },
  methods:{
    ...mapActions('company',['updateFilesCompany']),
    async handleImage(type) {
      let file = '';
      if (type === 'key'){
         file = document.querySelector('#mykey').files[0]
      }else{
          file = document.querySelector('#mycer').files[0]
      }

      const file_convert = await this.getBase64(file)

      file_convert.onload = (event) =>{
        if (type === 'key'){
          this.company.file_key = event.target.result.split(',')[1]
        }else{
          this.company.file_cert = event.target.result.split(',')[1]
        }
      };
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async updateFiles(data){
      this.overlay = true
      const response = await this.updateFilesCompany(this.company);
      if (response){
        this.overlay = false
        this.sweetAlertProduct('<span>Subida de archivos correcta/span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.$router.push('/checkout')
      }else{
        this.overlay = false
        this.sweetAlertProduct(response, 'white', 'error', 'red', 'white', 'top-end')
      }
     console.log(this.company)
    }

  }
}
</script>

<style scoped>

</style>
