<template>
  <v-container fluid>
    <v-col cols="3" sm="3" md="3" class="mt-10 mx-2 mx-sm-16">
      <v-text-field v-model="search" label="Buscar"></v-text-field>
    </v-col>
    <v-data-table
        :headers="headersTable"
        :items="products"
        :search="search"
        class="elevation-1 mt-10 mx-2 mx-sm-16"
    >

      <template v-slot:top>
        <v-dialog v-model="dialogEdit" max-width="1000px">
          <v-card>
            <v-card-title>
              <span class="headline">Producto {{ edit_product.name }}</span>
            </v-card-title>

            <v-card-text>

              <v-row>
                <v-col
                    cols="4"
                    sm="4"
                    md="4"
                >
                  <v-text-field
                      label="Nombre"
                      placeholder="Nombre"
                      v-model="edit_product.name"
                      color="input_dashboard"
                      style="color: white"
                      filled
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="4"
                    sm="4"
                    md="4"
                >
                  <v-text-field
                      label="Codigo de Barras"
                      v-model="edit_product.barcode"
                      color="input_dashboard"
                      placeholder="Codigo de Barras"
                      filled
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="4"
                    sm="4"
                    md="4"
                >
                  <v-text-field
                      label="Precio"
                      v-model="edit_product.price"
                      color="input_dashboard"
                      placeholder="Precio"
                      filled
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="4"
                    sm="4"
                    md="4"
                >
                  <v-autocomplete
                      v-model="edit_product.category_id"
                      :items="categories"
                      dense
                      color="input_dashboard"
                      filled
                      label="Categorias"
                  ></v-autocomplete>
                </v-col>
                <v-col  cols="4"
                        sm="4"
                        md="4">
                  <v-text-field
                      v-if="udm_state"
                      @click="udm_state = false"
                      label="Unidad de Medida"
                      placeholder="Unidad de Medida"
                      v-model="searchUdm"
                      color="input_dashboard"
                      style="color: white"
                      filled
                  ></v-text-field>
                  <v-autocomplete
                      v-else
                      no-data-text="Sin Resultados"
                      v-model="edit_product.sat_udm_id"
                      :items="udms"
                      color="input_dashboard"
                      :search-input.sync="searchUdm"
                      @keyup="SearchUdmF"
                      dense
                      filled
                      label="Unidad de Medida"
                  ></v-autocomplete>
                </v-col>
                <v-col  cols="4"
                        sm="4"
                        md="4">
                  <v-text-field
                      v-if="sat_product_state"
                      @click="sat_product_state = false"
                      label="Sat Producto"
                      placeholder="Sat Producto"
                      v-model="searchSatProductText"
                      color="input_dashboard"
                      style="color: white"
                      filled
                  ></v-text-field>
                  <v-autocomplete
                      v-else
                      v-model="edit_product.sat_producto_id"
                      no-data-text="Sin Resultados"
                      :items="sat_products"
                      color="input_dashboard"
                      :search-input.sync="searchSatProductText"
                      @keyup="SearchSatProduct"
                      dense
                      filled
                      label="Sat Producto"
                  ></v-autocomplete>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-select
                      v-model="taxes_edit"
                      :items="taxes"
                      color="input_dashboard"
                      filled
                      chips
                      label="Impuestos"
                      multiple
                  ></v-select>
                </v-col>

              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEdit">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="updateProduct(product_id)"> Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-toolbar flat height="0">
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <span @click="routerProducts()" style="text-decoration: none;">
                <v-btn
                    text
                    dark
                    style="position: relative; top: -100px; font-size: 18px;"
                >
                 <span class="text-title-tables"> Agregar Productos <i class="far fa-plus-square ml-1"></i></span>
                </v-btn>
              </span>
            </template>
          </v-dialog>

        </v-toolbar>
      </template>
      <template v-slot:item.price="{ item }">
        <p>{{formatNumber(item.price)}}</p>
      </template>
      <template v-slot:item.actions="{ item }">
        <span  style="color: #FFFFFF;text-decoration: none;">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">

              <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
              >

                <v-icon
                    small
                    class="mr-2"
                    style="color: #eec446; font-size: 25px"
                    @click="editItem(item)"
                >
                  fas fa-edit
                </v-icon>

              </v-btn>

            </template>
            <span>Editar</span>
          </v-tooltip>

        </span>
        <span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon
                    small
                    class="mr-2"
                    style="color: darkred; font-size: 25px"
                    @click="deletePro(item.id)"

                >
                  fas fa-trash
                </v-icon>
              </v-btn>
            </template>
            <span>Borrar Producto</span>
          </v-tooltip>
      </span>


      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>


<script>
import Overlay from "../General/Overlay";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TableProducts",
  data() {
    return {
      udm_state: true,
      sat_product_state: true,
      dialog: false,
      overlay: false,
      filters_id: '',
      dialogEdit: false,
      edit_form: [],
      dialogDelete: false,
      products: [],
      taxes_edit: [],
      edit_product:{
        name:'',
        barcode:'',
        price:'',
        sat_udm_id:'',
        sat_producto_id:'',
        category_id:'',
        tax:[]
      },
      searchUdm:'',
      searchSatProductText:'',
      categories: [],
      taxes:[],
      udms:[],
      sat_products:[],
      editedIndex: -1,
      product_id:'',
      search: "",
      headersTable: [
        {text: "id", value: "id"},
        {text: "Nombre", value: "name"},
        {text: "Precio", value: "price"},
        {text: "Unidad de medida", value: "SatUdm.name"},
        {text: "Sat Producto", value: "SatProducto.name"},
        {text: "Categoria", value: "ProductCategory.name"},
        {text: "Acciones", value: "actions", sortable: false},
      ],
      users: [],
      show1: false,

    };
  },
  computed: {
    ...mapGetters('auth', ['getUser']),

    formTitle() {
      return this.editedIndex === -1 ? "Agregar Clientes" : "Filtros";
    },
  },
  watch: {
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async created() {
    await this.initialize();
    await this.allCategories()
    await this.allTaxes()
    await this.SearchUdmF()

  },
  components: {
    Overlay
  },
  methods: {
    ...mapActions('products', ['findProducts','deleteProducts','findProductOne','updateProductModu']),
    ...mapActions('sat_request', ['findCategory','findTaxes','findUdmSearch','findSatProductSearch']),
    async initialize() {
      this.products = await this.findProducts();
    },
    routerProducts(){
      if (!this.getUser.Company){
        this.$router.push('/company')
      }else{
        this.$router.push('/agregar_productos')
      }

    },
    async allCategories(){
      this.categories = []
      const response = await this.findCategory();
      for (const a of response){
        this.categories.push({
          text: a.name,
          value: a.id,
        })
      }
    },
    async SearchUdmF(){
      this.udms = []
      const response = await this.findUdmSearch(this.searchUdm);
      for (const a of response){
        this.udms.push({
          text: '('+ a.code +')'+ ' '+a.name,
          value: a.id,
        })
      }
    },
    async SearchSatProduct(){
      this.sat_products = []
      const response = await this.findSatProductSearch(this.searchSatProductText);
      for (const a of response){
        this.sat_products.push({
          text: '('+ a.code +')'+ ' '+a.name,
          value: a.id,
        })
      }
    },
    async allTaxes(){
      this.taxes = []
      const response = await this.findTaxes();
      for (const a of response){
        this.taxes.push({
          text: a.name,
          value: a.id,
        })
      }
    },
    async updateClient(id) {
      const response = await this.updateClients({data: this.edit_user, id: id})
      if (response === true) {
        this.overlay = false
        this.sweetAlertProduct('<span>Cliente Editado correctamente</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.initialize();
        await this.closeEdit();
        this.udm_state = true
        this.sat_product_state= true
      } else {
        this.sweetAlertProduct('<span>Error al editar</span>', 'white', 'error', 'red', 'white', 'top-end')
        await this.initialize();
        this.udm_state = true
        this.sat_product_state= true
      }
    },
    async deletePro(id){
      const response = await this.deleteProducts({id:id});
      if (response){
        this.overlay = false
        this.sweetAlertProduct('<span>Producto Borrado correctamente</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.initialize();
      }else{
        this.overlay = false
        this.sweetAlertProduct('<span>Error al Borrar</span>', 'white', 'error', 'red', 'white', 'top-end')
        await this.initialize();
      }
    },
    closeEdit() {
      this.dialogEdit = false;
      this.product_id = '';
      this.edit_product.name = ''
      this.edit_product.barcode = ''
      this.edit_product.price = ''
      this.edit_product.sat_udm_id = ''
      this.edit_product.sat_producto_id = ''
      this.edit_product.category_id = ''
      this.searchUdm = ''
      this.edit_product.tax = ''
      this.edit_form = [];
      this.udm_state = true
      this.sat_product_state= true
    },
    async findProduct(id) {
      let tax = []
      const response = await this.findProductOne(id);
      this.edit_product.name = response.name
      this.edit_product.barcode = response.barcode
      this.edit_product.price = response.price
      this.edit_product.sat_udm_id = response.sat_udm_id
      this.edit_product.sat_producto_id = response.sat_producto_id
      this.edit_product.category_id = response.category_id
      this.searchUdm = response.SatUdm.name
      this.searchSatProductText = response.SatProducto.name
      for (const a of response.ProductTaxesRels){
        tax.push(
            a.tax_id,
        )
      }
      this.taxes_edit = tax
    },
    async updateProduct(id){
      let taxes = []
      for (const a of  this.taxes_edit){
        taxes.push({
          tax_id:a
        })
      }
      this.edit_product.tax = taxes
      const response = await this.updateProductModu({data:this.edit_product,id:id})
      if (response === true) {
        this.overlay = false
        this.sweetAlertProduct('<span>Producto Editado correctamente</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.initialize();
        await this.closeEdit();
      } else {
        this.sweetAlertProduct('<span>Error al editar</span>', 'white', 'error', 'red', 'white', 'top-end')
        await this.initialize();
      }
    },
    async editItem(item) {
      this.editedIndex = 1;
      this.product_id = item.id;
      await this.findProduct(this.product_id)
      this.dialogEdit = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },

}
</script>

<style scoped>

</style>
