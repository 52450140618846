<template>
  <div class="margin-top-general">
    <PageHeader
        class="mt-10 mx-2 mx-sm-16"
        :titlePageHeader="titlePageHeader = 'Crear Factura'"
        :breadcrumbps="breadcrumbps"
    />
    <AddInvoices/>
  </div>
</template>

<script>
import PageHeader from "../../../components/Dashboard/PageHeader";
import AddInvoices from "../../../components/Invoices/AddInvoices";
import {mapGetters} from "vuex";
export default {
  name: "NewInvoice",
  data() {
    return {
      breadcrumbps: [
        {
          text: "Facturas",
          disabled: false,
          href: "",
        },
        {
          text: "Crear Factura",
          disabled: false,
          href: "",
        },
      ],
    }

  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  created() {
    if (this.getUser.Company) {
      if (!this.getUser.Company.complete_files){
        this.$router.push('/archivos_compania')
      }
    }else{
      this.$router.push('/company')
    }
  },

  components: {
    PageHeader,
    AddInvoices
  }
}
</script>

<style scoped>

</style>
