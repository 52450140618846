<template>
  <div class="margin-top-general">
    <PageHeader
        class="mt-10 mx-2 mx-sm-16"
        :titlePageHeader="titlePageHeader = 'Crear Compañia'"
        :breadcrumbps="breadcrumbps"
    />
    <FormCompany/>
  </div>
</template>

<script>
import PageHeader from "../../../components/Dashboard/PageHeader";
import FormCompany from "../../../components/Company/FormCompany";
import {mapGetters} from "vuex";
export default {
  name: "ViewCompany",
  data() {
    return {
      breadcrumbps: [
        {
          text: "Compañia",
          disabled: false,
          href: "",
        },
        {
          text: "Crear Compañia",
          disabled: false,
          href: "",
        },
      ],
      notes: [],
      company_information:''
    }

  },
  components:{
    PageHeader,
    FormCompany
  },
  created() {
    if (this.getUser.Company){
      this.$router.push('/')
    }
  },
  computed:{
    ...mapGetters('auth',['getUser'])
  }
}
</script>

<style scoped>

</style>
