<template>
  <div class="margin-top-general">
    <PageHeader
        class="mt-10 mx-2 mx-sm-16"
        :titlePageHeader="titlePageHeader = 'Editar Factura'"
        :breadcrumbps="breadcrumbps"
    />
    <div v-if="invoice">
      <InvoiceEdit :invoice_data="invoice"/>
    </div>

  </div>
</template>

<script>
import PageHeader from "../../../components/Dashboard/PageHeader";
import InvoiceEdit from "../../../components/Invoices/InvoiceEdit";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "ViewInvoiceEdit",
  data() {
    return {
      invoice:'',
      breadcrumbps: [
        {
          text: "Facturas",
          disabled: false,
          href: "",
        },
        {
          text: "Editar Factura",
          disabled: false,
          href: "",
        },
      ],
    }

  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  methods:{
    ...mapActions('invoices', ['findInvoiceOne']),
    async findInvoice(){
      const id = this.$route.params.id;
      this.invoice = await this.findInvoiceOne(id)

    }
  },
  async created() {
    if (this.getUser.Company) {
      if (!this.getUser.Company.complete_files){
        this.$router.push('/archivos_compania')
      }
    }else{
      this.$router.push('/company')
    }
    await this.findInvoice()
  },

  components: {
    PageHeader,
    InvoiceEdit
  }
}
</script>

<style scoped>

</style>
