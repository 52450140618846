import store from "./index";

const URL_API = process.env.NODE_ENV === 'development' ? 'http://localhost:4017' : 'https://qa.app.hoyfacturo.com';
const URL_API_CAKE = process.env.NODE_ENV === 'development' ? 'http://localhost' : 'https://cfdii2.hoyfacturo.com';

export const requestFetch = async (path, method, data ) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", store.getters["auth/getToken"]);
    myHeaders.append("Content-Type", "application/json");


    const requestOptions = {
        method: method,
        headers: myHeaders,
        body: JSON.stringify(data) || null,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.json();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        console.log(error);
        return {error};
    }
}

export const requestFetchFile = async (path, method, data ) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", store.getters["auth/getToken"]);
    myHeaders.append("Content-Type", "*");
    const requestOptions = {
        method: method,
        headers: myHeaders,
        redirect: 'follow'

    };

    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.text();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        console.log(error);
        return {error};
    }
}
export const requestFetchFileCake = async (path, method, data ) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "CAKEPHP=7ddc27f6f95af3cf1431b52f6789d115");
    const requestOptions = {
        method: method,
        body: JSON.stringify(data) || null,
        headers: myHeaders,
        redirect: 'follow'

    };

    try {
        const response = await fetch(`${URL_API_CAKE}${path}`, requestOptions);
        const data = await response.json();
        if (data.error) throw data.error;
        return data;
    } catch (error) {
        console.log(error);
        return {error};
    }
}
