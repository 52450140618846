import { render, staticRenderFns } from "./NewProducts.vue?vue&type=template&id=2ce8d3b2&scoped=true&"
import script from "./NewProducts.vue?vue&type=script&lang=js&"
export * from "./NewProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ce8d3b2",
  null
  
)

export default component.exports