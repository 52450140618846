<template>
  <v-container fluid>
    <v-col cols="3" sm="3" md="3" class="mt-10 mx-2 mx-sm-16">
      <v-text-field v-model="search" label="Buscar"></v-text-field>
    </v-col>
    <v-data-table
        :headers="headersTable"
        :items="clients"
        :search="search"
        class="elevation-1 mt-10 mx-2 mx-sm-16"
    >

      <template v-slot:top>
        <v-dialog v-model="dialogEdit" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">Cliente {{ edit_user.full_name }}</span>
            </v-card-title>

            <v-card-text>

              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      label="Nombre"
                      placeholder="Nombre"
                      v-model="edit_user.full_name"
                      color="input_dashboard"
                      style="color: white"
                      filled
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      label="RFC"
                      v-model="edit_user.vat"
                      color="input_dashboard"
                      placeholder="RFC"
                      filled
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      label="Codigo Postal"
                      v-model="edit_user.zip_code"
                      color="input_dashboard"
                      placeholder="Codigo Postal"
                      filled
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-btn
                      class="pa-6"
                      block
                      style="color: white"
                      color="primary"
                      @click="verifyCode()"
                  >
                    Validar Codigo Postal
                  </v-btn>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      label="Ciudad"
                      v-model="edit_user.city"
                      color="input_dashboard"
                      placeholder="Ciudad"
                      filled
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      label="Estado"
                      v-model="edit_user.state"
                      color="input_dashboard"
                      placeholder="Estado"
                      filled
                  ></v-text-field>
                </v-col>

                <v-col
                    cols="6"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      label="Correo"
                      v-model="edit_user.email"
                      color="input_dashboard"
                      placeholder="Correo"
                      filled
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      label="Celular"
                      v-model="edit_user.phone"
                      color="input_dashboard"
                      placeholder="Celular"
                      filled
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-autocomplete
                      v-model="edit_user.regimen_fiscal_id"
                      :items="items"
                      dense
                      color="input_dashboard"
                      filled
                      label="Regimen Fiscal"
                  ></v-autocomplete>
                </v-col>

              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEdit">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="updateClient(client_id)"> Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-toolbar flat height="0">
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <span @click="routerClient()" style="text-decoration: none;">
                <v-btn
                    text
                    dark
                    style="position: relative; top: -100px; font-size: 18px;"
                >
                 <span class="text-title-tables"> Agregar Cliente <i class="far fa-plus-square ml-1"></i></span>
                </v-btn>
              </span>
            </template>
          </v-dialog>

        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <span style="color: #FFFFFF;text-decoration: none;">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">

              <v-btn
                  icon
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
              >

                <v-icon
                    small
                    class="mr-2"
                    style="color: #eec446; font-size: 25px"

                >
                  fas fa-edit
                </v-icon>

              </v-btn>

            </template>
            <span>Editar</span>
          </v-tooltip>

        </span>
        <span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon
                    small
                    class="mr-2"
                    style="color: darkred; font-size: 25px"
                    @click="deleteClients(item.id)"

                >
                  fas fa-trash
                </v-icon>
              </v-btn>
            </template>
            <span>Borrar Cliente</span>
          </v-tooltip>
      </span>


      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>


<script>
import Overlay from "../General/Overlay";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TableClients",
  data() {
    return {
      dialog: false,
      overlay: false,
      client_id: '',
      dialogEdit: false,
      edit_form: [],
      dialogDelete: false,
      clients: [],
      editedIndex: -1,
      edit_user: {
        full_name: '',
        vat: '',
        regimen_fiscal_id: '',
        regimen_fiscal_text: '',
        city: '',
        state: '',
        zip_code: '',
        email: '',
        phone: '',
      },
      search: "",
      headersTable: [
        {text: "id", value: "id"},
        {text: "Razón Social", value: "full_name"},
        {text: "RFC", value: "vat"},
        {text: "Codigo Postal", value: "zip_code"},
        {text: "Regimen Fiscal", value: "SatRegimenFiscal.name"},
        {text: "Acciones", value: "actions", sortable: false},
      ],
      users: [],
      items: [],
      show1: false,

    };
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    formTitle() {
      return this.editedIndex === -1 ? "Agregar Clientes" : "Cliente";
    },
  },
  watch: {
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async created() {
    await this.initialize();
    await this.RegimenItems();

  },
  components: {
    Overlay
  },
  methods: {
    ...mapActions('clients', ['findClients', 'deleteClient', 'findClientOne', 'zipCodeVerify', 'updateClients']),
    ...mapActions('sat_request', ['findRegimenFiscal']),
    routerClient() {
      if (!this.getUser.Company) {
        this.$router.push('/company')
      } else {
        this.$router.push('/agregar_clientes')
      }

    },
    async initialize() {
      this.clients = await this.findClients();
    },
    async verifyCode() {
      const response = await this.zipCodeVerify({code: this.edit_user.zip_code})
      if (response === false) {
        this.sweetAlertProduct('Error coloque sus datos', 'white', 'error', 'red', 'white', 'top-end')
      } else {
        this.edit_user.city = response.country_locality_sat_code.name ? response.country_locality_sat_code.name : '';
        this.edit_user.state = response.country_state_sat_code.name ? response.country_state_sat_code.name : '';
      }
    },
    async findClient(id) {
      const response = await this.findClientOne(id);
      this.edit_user.full_name = response.full_name
      this.edit_user.vat = response.vat
      this.edit_user.zip_code = response.zip_code
      this.edit_user.phone = response.phone
      this.edit_user.regimen_fiscal_id = response.regimen_fiscal_id
      this.edit_user.city = response.city
      this.edit_user.state = response.state
      this.edit_user.email = response.email
      this.edit_user.regimen_fiscal_text = response.SatRegimenFiscal.name
    },
    async RegimenItems() {
      this.items = []
      const response = await this.findRegimenFiscal();
      for (const a of response) {
        this.items.push({
          text: a.name,
          value: a.id,
        })
      }
    },
    async deleteClients(id) {
      const response = await this.deleteClient({id: id});
      if (response) {
        this.overlay = false
        this.sweetAlertProduct('<span>Cliente Borrado correctamente</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.initialize();
      } else {
        this.overlay = false
        this.sweetAlertProduct('<span>Error al Borrar</span>', 'white', 'error', 'red', 'white', 'top-end')
        await this.initialize();
      }
    },
    closeEdit() {
      this.dialogEdit = false;
      this.client_id = '';
      this.edit_user = {
        full_name: '',
        vat: '',
        regimen_fiscal_id: '',
        regimen_fiscal_text: '',
        city: '',
        state: '',
        zip_code: '',
        email: '',
        phone: '',
      }
    },
    async updateClient(id) {
      const response = await this.updateClients({data: this.edit_user, id: id})
      if (response === true) {
        this.overlay = false
        this.sweetAlertProduct('<span>Cliente Editado correctamente</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.initialize();
        await this.closeEdit();
      } else {
        this.sweetAlertProduct('<span>Error al editar</span>', 'white', 'error', 'red', 'white', 'top-end')
        await this.initialize();
      }
    },
    async editItem(item) {
      this.editedIndex = 1;
      this.client_id = item.id;
      await this.findClient(this.client_id)
      this.dialogEdit = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },

}
</script>

<style scoped>

</style>
