import router from "../router";
import store from '../store';
import {requestFetch, requestFetchFile, requestFetchFileCake} from '../store/config'
export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        //list clients
        async findInvoices({ commit }) {
            const response = await requestFetch('/api/invoice/all','GET');
            return response.invoices
        },
        async stampInvoice({ commit },data) {
            const response = await requestFetch('/api/invoice/generate_xml','POST', data);
            if (response.msg){
                return true
            }else{
                return response.error
            }

        },
        async downloadXmlFile({ commit },data) {
            const response = await requestFetchFile(`/api/invoice/download_xml/${data.id}`,'GET');
            const fileURL = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `${data.uuid}.xml`);
            document.body.appendChild(fileLink);
            fileLink.click();
        },
        async downloadXPdfFile({ commit },data) {
            const response = await requestFetchFileCake(`/pdf/generate_pdf`,'POST', {uuid:data.uuid, xml:data.xml});
            const binaryImg = atob(response.msg);
            const length = binaryImg.length;
            const arrayBuffer = new ArrayBuffer(length);
            const uintArray = new Uint8Array(arrayBuffer);

            for (let i = 0; i < length; i++) {
                uintArray[i] = binaryImg.charCodeAt(i);
            }

            const fileBlob = new Blob([uintArray], { type: 'application/pdf' });
            const fileURL = window.URL.createObjectURL(new Blob([fileBlob]));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `${data.uuid}.pdf`);
            document.body.appendChild(fileLink);
            fileLink.click();
        },
        async findSumInvoice({ commit }) {
            const response = await requestFetch('/api/invoice/sum','GET');
            return response.total
        },
        async findRecentInvoice({ commit }) {
            const response = await requestFetch('/api/invoice/recent','GET');
            return response.invoices
        },
        async findInvoiceOne({ commit },id) {
            const response = await requestFetch(`/api/invoice/one/${id}`,'GET');
            return response.invoices
        },
        async generateInvoice({ commit },data) {
            const response = await requestFetch('/api/invoice/create','post', data);
            if (response.msg){
                return true
            }else{
                return false
            }

        },
        async updateInvoice({ commit },data) {
            const response = await requestFetch(`/api/invoice/draft/${data.id}`,'PUT', data);
            if (response.msg){
                return true
            }else{
                return false
            }

        },
    },
    getters: {

    }

}
