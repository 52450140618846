import router from "../router";
import store from '../store';
import {requestFetch} from '../store/config'

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        //create company
        async createCompany({commit}, data) {
            data.user_id = await store.getters["auth/getUser"].id
            const response = await requestFetch('/api/company/register', 'POST', data);
            if (response.access_token) {
                await store.dispatch('auth/setToken', 'Bearer ' +  response.access_token);
                return true
            } else {
                return response.error
            }
        },
        async updateCompanyInformation({commit}, {id,data}) {
            const response = await requestFetch(`/api/company/sat/information/${id}`, 'put', data);
            if (response.access_token) {
                await store.dispatch('auth/setToken', 'Bearer ' +  response.access_token);
                return true
            } else {
                return response.error
            }
        },
        async updateCompanyInformationFiles({commit}, {id,data}) {
            const response = await requestFetch(`/api/company/sat/files/${id}`, 'put', data);
            if (response.msg) {
                return true
            } else {
                return response.error
            }
        },
        async updateFilesCompany({commit}, data) {
            const id = await store.getters["auth/getUser"].Company.id
            const response = await requestFetch(`/api/company/sat/${id}`, 'PUT', data);
            if (response.msg) {
                return true
            } else {
                return response.error
            }
        },
        async getCompany({commit}) {
            const response = await requestFetch(`/api/company/information`, 'GET');
            return response.company
        },
    },
    getters: {}

}
