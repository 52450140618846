<template>
  <div class="margin-top-general">
    <v-container fluid>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step
                :complete="e1 > 1"
                step="1"
            >
              Elegir paquete
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="e1 > 2"
                step="2"
            >
              Metodo de pago
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3">
              Finalizado
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
            </v-stepper-content>
            <v-stepper-content step="2">

            </v-stepper-content>

            <v-stepper-content step="3">

              <v-col cols="12">
                <div align="center">

                    <h1>Bienvenido a Hoyfacturo:</h1> <br>
                    <h2>{{getUser.full_name}} <br> {{getUser.email}}</h2> <br>
                    <h2>Paquete: {{getUser.SubscriptionOrder.SubscriptionPackage.title}}</h2>
                    <v-btn
                        to="/"
                        style="margin-top: 10px; margin-bottom: 10px"
                        color="primary"
                    >
                      Continuar
                    </v-btn>
                  </div>
              </v-col>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

    </v-container>
  </div>
</template>

<script>
import PageHeader from "../../../components/Dashboard/PageHeader";
import {mapGetters} from "vuex";

export default {
  name: "Welcome",
  data() {
    return {
      e1:3,
      breadcrumbps: [
        {
          text: "Usuario",
          disabled: false,
          href: "",
        },
        {
          text: "Pago",
          disabled: false,
          href: "",
        },
      ],

    }

  },
  computed:{
    ...mapGetters('auth', ['getUser'])
  },
  components: {
    PageHeader,
  }
}
</script>

<style scoped>

</style>
