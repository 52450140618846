<template>
  <v-container fluid>
    <v-row class="d-flex justify-center  pa-8 ml-2">

    </v-row>
    <v-form @submit.prevent="UpdateDraftInvoice(invoice)">
      <v-row class="d-flex pa-8 ml-2 mr-2 layout-form">
        <v-col
            cols="12"
            sm="3"
            md="3"
        >
          <v-autocomplete
              v-model="invoice.customer_id"
              :items="customers"
              dense
              no-data-text="Sin Resultados"
              color="input_dashboard"
              filled
              label="Clientes"
          ></v-autocomplete>
        </v-col>
        <v-col
            cols="12"
            sm="3"
            md="3"
        >
          <v-autocomplete
              v-model="invoice.sat_uso_cfdi_id"
              :items="uso_cfdi"
              dense
              no-data-text="Sin Resultados"
              color="input_dashboard"
              filled
              label="Uso Cfdi"
          ></v-autocomplete>
        </v-col>
        <v-col
            cols="12"
            sm="3"
            md="3"
        >
          <v-autocomplete
              v-model="invoice.sat_tipo_comprobante"
              :items="vouchers"
              dense
              no-data-text="Sin Resultados"
              color="input_dashboard"
              filled
              label="Comprobante"
          ></v-autocomplete>
        </v-col>
        <v-col
            cols="12"
            sm="3"
            md="3"
        >
          <v-autocomplete
              v-model="invoice.sat_periocidad"
              :items="periocidad"
              dense
              no-data-text="Sin Resultados"
              color="input_dashboard"
              filled
              :disabled="!verify_public"
              :label="verify_public ? 'Periocidad' : 'No es necesario en este caso'"
          ></v-autocomplete>
        </v-col>
        <v-col
            cols="12"
            sm="3"
            md="3"
        >
          <v-autocomplete
              v-model="invoice.sat_metodo_pago_id"
              :items="pay_methods"
              dense
              no-data-text="Sin Resultados"
              color="input_dashboard"
              filled
              label="Metodo de pago"
          ></v-autocomplete>
        </v-col>
        <v-col
            cols="12"
            sm="3"
            md="3"
        >
          <v-autocomplete
              v-model="invoice.pay_method_id"
              :items="metodos"
              no-data-text="Escriba el sat metodo de pago"
              color="input_dashboard"
              :search-input.sync="metodos_search"
              @keyup="getSatMetodoPago"
              dense
              filled
              label="Forma de pago"
          ></v-autocomplete>
        </v-col>
        <v-col
            cols="12"
            sm="3"
            md="3"
        >
          <v-autocomplete
              v-model="invoice.currency_id"
              :items="currency"
              no-data-text="Escriba la moneda"
              color="input_dashboard"
              :search-input.sync="currency_search"
              @keyup="getCurrency"
              dense
              filled
              label="Moneda"
          ></v-autocomplete>
        </v-col>
        <v-col
            cols="12"
            sm="3"
            md="3"
        >
          <v-menu
              ref="menu"
              v-model="invoice.date_invoice"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y

              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="date"
                  label="Dia de factura"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                locale="es-MX"
                v-model="date"
                no-title
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="12"
        >
          <table>
            <tr>
              <th>Producto</th>
              <th>Nombre</th>
              <th>Precio Unitario</th>
              <th>Cantidad</th>
              <th>Impuestos</th>
              <th>Descuento</th>
              <th>Subtotal</th>
              <th>Acciones</th>
            </tr>
            <tr v-for="(p, idx) in product_lines" :key="idx">
              <td>
                <v-autocomplete
                    filled
                    :items="products"
                    v-model="p.product_id"
                    color="input_dashboard"
                    @input="getProduct(p.product_id,idx)"
                >
                </v-autocomplete>
              </td>
              <td>
                <v-text-field
                    v-model="p.name_product"
                    color="input_dashboard"
                    disabled
                    filled
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="p.price_unit"
                    color="input_dashboard"
                    @keyup="changePriceUnit(idx)"
                    filled
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="p.quantity"
                    color="input_dashboard"
                    @input="changQty(idx)"
                    type="number"
                    filled
                ></v-text-field>
              </td>
              <td>
                <v-select
                    v-model="p.taxes"
                    :items="taxes"
                    color="input_dashboard"
                    filled
                    chips
                    :item-value="p.taxes.tax_id"
                    label="Impuestos"
                    multiple
                    @input="changeTaxes(idx,p.taxes)"
                    return-object
                >

                </v-select>
              </td>
              <td>
                <v-text-field
                    v-model="p.discount"
                    @input="discountPercentage(idx)"
                    type="number"
                    color="input_dashboard"
                    filled
                ></v-text-field>
              </td>
              <td>
                <p>{{ formatNumber(p.subtotal_untaxed) }}</p>

              </td>
              <td>
                <v-btn v-if="p.new_line" icon @click="removeLine(idx)">
                  <v-icon
                      small
                      style="color: red; font-size: 25px">
                    fas fa-trash
                  </v-icon>
                </v-btn>
                <v-btn v-else icon @click="addRow(idx)">
                  <v-icon
                      small
                      style="color: green; font-size: 25px">
                    fas fa-check-circle
                  </v-icon>
                </v-btn>
              </td>
            </tr>


          </table>

        </v-col>
        <v-col
            cols="12"
            md="12"
        >
          <p style="text-align: right; font-size: 20px"> Subtotal: <strong>{{ formatNumber(total_untaxed) }}</strong>
          </p>
          <p style="text-align: right; font-size: 20px"> Total: <strong>{{ formatNumber(total) }}</strong></p>
        </v-col>
        <v-col
            cols="12"
            md="12"
        >
          <v-textarea
              filled
              name="input-7-4"
              label="Comentario"
              v-model="invoice.comment"
          ></v-textarea>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="12"
        >
          <v-btn
              class="pa-6"
              block
              style="color: white"
              color="primary"
              type="submit"
          >
            Guardar Datos
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <Overlay :overlay="overlay"/>
  </v-container>
</template>

<script>
import Overlay from "../General/Overlay";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "InvoiceEdit",
  props: {
    invoice_data: Object
  },
  async created() {
    if (this.invoice_data.state !== 'draft') {
      await this.$router.push('/facturas')
    }
    await this.joinInvoice();
    await this.getCustomers();
    await this.getSatUsoCdfi();
    await this.getPayMethod();
    await this.getProducts();
    await this.allTaxes();
    await this.getCurrency();
    await this.getComprobante();
    await this.getPeriocidad();
    await this.verifyGeneral()
  },
  data() {
    return {
      date: '',
      menu: false,
      overlay: false,
      customers: [],
      periocidad: [],
      vouchers: [],
      verify_public: false,
      pay_methods: [],
      metodos: [],
      uso_cfdi: [],
      taxes_edit: [],
      taxes: [],
      currency: [],
      currency_search: '',
      metodos_search: '',
      products: [],
      total_invoices: 0,
      product_lines: [
        {
          name_product: '',
          product_id: '',
          quantity: 1,
          price_unit: '',
          discount: '',
          subtotal: '',
          taxes: [],
          subtotal_untaxed: '',
          new_line: true
        },
      ],
      invoice: {
        comment: '',
        id: '',
        date_invoice: '',
        customer_id: '',
        currency_id: '',
        sat_uso_cfdi_id: '',
        sat_metodo_pago_id: '',
        sat_tipo_comprobante: '',
        sat_periocidad: '',
        pay_method_id: '',
        lines: []
      },
    }
  },
  components: {
    Overlay
  },


  computed: {
    ...mapGetters('auth', ['getUser']),
    total() {
      return this.product_lines.reduce(
          (acc, item) => acc + item.subtotal,
          0
      );
    },
    total_untaxed() {
      return this.product_lines.reduce(
          (acc, item) => acc + item.price_unit * item.quantity - item.discount,
          0
      );
    }
  },
  methods: {
    ...mapActions('sat_request', ['findRegimenFiscal', 'findSatCfdi', 'findCurrencyAll', 'findPayMethod', 'findSatMetodo', 'findTaxes','findComprobante','findPeriocidad']),
    ...mapActions('clients', ['findClients']),
    ...mapActions('invoices', ['updateInvoice']),
    ...mapActions('products', ['findProducts', 'findProductOne']),
    async getComprobante() {
      this.vouchers = []
      const response = await this.findComprobante()
      for (const a of response) {
        this.vouchers.push({
          text: '(' + a.code + ')' + ' ' + a.name,
          value: a.id,
        })
      }
    },
    async joinInvoice(){
      let lines = [];

      this.date = this.invoice_data.date_invoice
      this.invoice.comment = this.invoice_data.comment
      this.invoice.id = this.invoice_data.id
/*      this.invoice.date_invoice = this.invoice_data.date_invoice
      this.data = this.invoice_data.date_invoice*/
      this.invoice.customer_id = this.invoice_data.customer_id

      this.invoice.currency_id = this.invoice_data.currency_id
      this.invoice.sat_uso_cfdi_id = this.invoice_data.sat_uso_cfdi_id
      this.invoice.sat_metodo_pago_id = this.invoice_data.sat_metodo_pago_id
      this.invoice.pay_method_id = this.invoice_data.pay_method_id
      this.invoice.sat_tipo_comprobante = this.invoice_data.sat_tipo_comprobante
      this.invoice.sat_periocidad = this.invoice_data.sat_periocidad_id
      for(const a of this.invoice_data.AccountInvoiceLines){
        const ta = await this.joinTaxes(a.AccountTaxesRels)
        lines.push({
          name_product: a.Product.name,
          product_id: a.Product.id,
          quantity: a.quantity,
          price_unit: a.price_unit,
          discount: a.discount,
          subtotal:  a.price_total,
          subtotal_untaxed:  a.price_subtotal - a.discount,
          taxes: ta,
          new_line: true
        })
      }
      lines.push({
        name_product: '',
        product_id: '',
        quantity: 1,
        price_unit: '',
        discount: '',
        subtotal: '',
        subtotal_untaxed: '',
        taxes: [],
        new_line: false
      })

      this.product_lines = lines
    },
    async joinTaxes(data){
      let taxes = []
      for (const b of data){
        taxes.push({
          value: {tax_id: b.Tax.id, total: b.Tax.value}
        })
      }
      return taxes
    },
    addRow(id) {
      this.product_lines[id].new_line = true
      this.product_lines.push({
        name_product: '',
        product_id: '',
        quantity: 1,
        price_unit: '',
        discount: '',
        subtotal: '',
        taxes: [],
        new_line: false
      })
    },
    removeLine(id) {
      this.product_lines.splice(id, 1)
    },
    async UpdateDraftInvoice(invoice) {

      this.overlay = true
      let lines = [];
      this.invoice.company_id = this.getUser.Company.id;
      this.invoice.company_id = this.getUser.Company.id;
      this.invoice.user_id = this.getUser.id;
      this.invoice.date_invoice = this.date;

      for (const a of this.product_lines) {
        if (a.name_product && a.product_id) {
          const resp = await this.linesCreate(a.taxes)
          lines.push({
            name: a.name_product,
            product_id: a.product_id,
            company_id: this.getUser.Company.id,
            price_unit: a.price_unit,
            quantity: a.quantity,
            discount: a.discount,
            currency_id: invoice.currency_id,
            tax: resp
          })
        }
      }

      this.invoice.lines = lines
      this.invoice.date_invoice = this.date

      const response = await this.updateInvoice(this.invoice);
      if (response) {
        this.overlay = false
        this.sweetAlertProduct('<span>Facturada editada correctamente</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.$router.push('/facturas')
      } else {
        this.overlay = false
        this.sweetAlertProduct('Error al crear', 'white', 'error', 'red', 'white', 'top-end')
      }

    },
    async linesCreate(lines) {
      let newLines = []
      for (const b of lines) {
        newLines.push({
          tax_id: b.value.tax_id,
          value: b.value.total,
        })
      }
      return newLines

    },
    async getProduct(product_id, id) {
      this.overlay = true
      let tax = []
      let sum_tax = 0
      const response = await this.findProductOne(product_id);
      this.product_lines[id].name_product = response.name;
      this.product_lines[id].product_id = response.id;
      this.product_lines[id].price_unit = response.price;
      this.product_lines[id].discount = 0;
      const subtotal = response.price * this.product_lines[id].quantity
      const subtotal_res = subtotal - this.product_lines[id].discount
      for (const b of response.ProductTaxesRels) {
        const total_tax = b.Tax.value / 100;

        const total_multi = subtotal_res * total_tax;
        sum_tax += total_multi
      }

      for (const a of response.ProductTaxesRels) {
        tax.push(
            {
              value: {tax_id: a.tax_id, total: a.Tax.value},
            }
        )
      }
      this.product_lines[id].taxes = tax
      this.product_lines[id].subtotal = subtotal_res + sum_tax
      this.product_lines[id].subtotal_untaxed = subtotal_res
      this.overlay = false
    },
    changePriceUnit(line_id) {
      let sum_tax = 0
      const response = this.product_lines[line_id];
      const subtotal = response.price_unit * this.product_lines[line_id].quantity
      const subtotal_res = subtotal - this.product_lines[line_id].discount
      for (const b of response.taxes) {
        const total_tax = b.value.total / 100;
        const total_multi = subtotal_res * total_tax;
        sum_tax += total_multi
      }
      this.product_lines[line_id].subtotal = subtotal_res + sum_tax
      this.product_lines[line_id].subtotal_untaxed = subtotal_res
    },
    changeTaxes(line_id) {
      let sum_tax = 0
      const response = this.product_lines[line_id];
      const subtotal = response.price_unit * this.product_lines[line_id].quantity
      const subtotal_res = subtotal - this.product_lines[line_id].discount
      for (const b of response.taxes) {
        const total_tax = b.value.total / 100;
        const total_multi = subtotal_res * total_tax;
        sum_tax += total_multi
      }
      this.product_lines[line_id].subtotal = subtotal_res + sum_tax
      this.product_lines[line_id].subtotal_untaxed = subtotal_res
    },
    changQty(line_id) {
      let sum_tax = 0
      const response = this.product_lines[line_id];
      const subtotal = response.price_unit * this.product_lines[line_id].quantity
      const subtotal_res = subtotal - this.product_lines[line_id].discount
      for (const b of response.taxes) {
        const total_tax = b.value.total / 100;

        const total_multi = subtotal_res * total_tax;
        sum_tax += total_multi
      }
      this.product_lines[line_id].subtotal = subtotal_res + sum_tax
      this.product_lines[line_id].subtotal_untaxed = subtotal_res
    },
    discountPercentage(line_id) {
      let sum_tax = 0
      const response = this.product_lines[line_id];
      const subtotal = response.price_unit * this.product_lines[line_id].quantity
      const subtotal_res = subtotal - this.product_lines[line_id].discount
      for (const b of response.taxes) {
        const total_tax = b.value.total / 100;

        const total_multi = subtotal_res * total_tax;
        sum_tax += total_multi
      }
      this.product_lines[line_id].subtotal = subtotal_res + sum_tax
      this.product_lines[line_id].subtotal_untaxed = subtotal_res
    },
    async allTaxes() {
      this.taxes = []
      const response = await this.findTaxes();
      for (const a of response) {
        this.taxes.push({
          text: a.name,
          value: {tax_id: a.id, total: a.value}
        })
      }
    },
    async getProducts() {
      this.products = []
      const response = await this.findProducts();
      for (const a of response) {
        this.products.push({
          text: a.name,
          value: a.id,
        })
      }
    },
    async getCustomers() {
      this.customers = []
      const response = await this.findClients()
      for (const a of response) {
        this.customers.push({
          text: a.full_name,
          rfc: a.vat,
          value: a.id,
        })
      }
    },
    async getSatUsoCdfi() {
      this.uso_cfdi = []
      const response = await this.findSatCfdi()
      for (const a of response) {
        this.uso_cfdi.push({
          text: '(' + a.code + ')' + ' ' + a.name,
          value: a.id,
        })
      }

    },
    async getSatMetodoPago() {
      this.metodos = []
      const response = await this.findSatMetodo(this.metodos_search)
      for (const a of response) {
        this.metodos.push({
          text: '(' + a.code + ')' + ' ' + a.name,
          value: a.id,
        })
      }
    },
    async verifyGeneral() {
      for (const a of this.customers){
        if (a.value === this.invoice.customer_id){
          if (a.rfc === "XAXX010101000"){
            this.verify_public = true
            return
          }else{
            this.verify_public = false
            return
          }
        }
      }

    },
    async getPeriocidad() {
      this.periocidad = []
      const response = await this.findPeriocidad();
      for (const a of response) {
        this.periocidad.push({
          text: '(' + a.code + ')' + ' ' + a.name,
          value: a.id,
        })
      }
    },
    async getPayMethod() {
      this.pay_methods = [];
      const response = await this.findPayMethod()
      for (const a of response) {
        this.pay_methods.push({
          text: '(' + a.code + ')' + ' ' + a.name,
          value: a.id,
        })
      }

    },
    async getCurrency() {
      this.currency = []
      const response = await this.findCurrencyAll();
      for (const a of response) {
        this.currency.push({
          text: '(' + a.name + ')' + ' ' + a.currency_unit_label,
          value: a.id,
        })
      }

    },
  },

}
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}
</style>

