<template>
  <div class="margin-top-general">
    <PageHeader
        class="mt-10 mx-2 mx-sm-16"
        :titlePageHeader="titlePageHeader = 'Agregar cliente'"
        :breadcrumbps="breadcrumbps"
    />
    <AddClients/>

  </div>
</template>

<script>
import PageHeader from "../../../components/Dashboard/PageHeader";
import AddClients from "../../../components/Clients/AddClients";
export default {
  name: "NewClient",
  data() {
    return {
      breadcrumbps: [
        {
          text: "Clientes",
          disabled: false,
          href: "",
        },
        {
          text: "Agregar Cliente",
          disabled: false,
          href: "",
        },
      ],
      notes: [],
      company_information:''
    }

  },

  components: {
    PageHeader,
    AddClients
  }
}
</script>

<style scoped>

</style>
