<template>
  <div class="margin-top-general">
    <PageHeader
        class="mt-10 mx-2 mx-sm-16"
        :titlePageHeader="titlePageHeader = 'Lista de Facturas'"
        :breadcrumbps="breadcrumbps"
    />
    <TableInvoice/>

  </div>
</template>

<script>
import PageHeader from "../../../components/Dashboard/PageHeader";
import TableInvoice from "../../../components/Invoices/TableInvoice";
import {mapGetters} from "vuex";

export default {
  name: "ListInvoice",
  data() {
    return {
      breadcrumbps: [
        {
          text: "Facturas",
          disabled: false,
          href: "",
        },
        {
          text: "Lista de Facturas",
          disabled: false,
          href: "",
        },
      ],
      notes: [],
      company_information: ''
    }

  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  created() {
    if (this.getUser.Company) {
      if (!this.getUser.Company.complete_files){
        this.$router.push('/archivos_compania')
      }
    }else{
      this.$router.push('/company')
    }
  },

  components: {
    PageHeader,
    TableInvoice
  }
}
</script>

<style scoped>

</style>
