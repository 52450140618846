<template>
  <v-container fluid>
    <v-row class="d-flex   pa-8 ml-2">
      <v-col
          cols="12"
          sm="12"
          md="12"
      >
        <v-row class="d-flex pa-8 ml-2 mr-2 layout-form">
          <v-col cols="12" v-if="!verify_count">
            <h1 style="text-align: center">No tiene ningún plan click en el butón: </h1>
            <v-btn
                to="/checkout"
                class="pa-6"
                block
                style="color: white"
                color="primary"
            >
              Elegir plan
            </v-btn>
          </v-col>
          <v-col cols="12" v-else >
            <h1 style="text-align: center">Plan: {{order.SubscriptionPackage.title}} </h1>
            <h1 style="text-align: center">Referencia: {{order.reference}} </h1>
            <h2 style="text-align: center">Precio: {{formatNumber(order.SubscriptionPackage.price)}} </h2>
            <h2 style="text-align: center">Suscripción Por un Mes </h2>
            <v-row v-for="a in order.SubscriptionOrderLines" :key="a.id">
              <v-col cols="6">
                <h2 style="text-align: right">Inicio: {{a.initial_date}}</h2>
              </v-col>
              <v-col cols="6">
                <h2>Final: {{a.end_date}}</h2>
              </v-col>
            </v-row>

            <v-btn
                @click="cancelSubscription(order.id,order.stripe_subscription_id)"
                class="pa-6"
                block
                style="color: white; margin-top: 20px"
                color="primary"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Overlay :overlay="overlay"/>

  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Overlay from "../General/Overlay";
export default {
  name: "Detail",
  data(){
    return{
      overlay:false,
      verify_count:false,
      order:{
        SubscriptionPackage:{
          title:''
        }
      }
    }
  },
  components:{
    Overlay
  },
  async created(){
    if (!this.getUser.SubscriptionOrder.is_active){
      this.verify_count = false
    }else if(!this.getUser.SubscriptionOrder){
      this.verify_count = false
    }else{
      this.verify_count = true
      await this.getSub(this.getUser.SubscriptionOrder.id)
    }

  },
  methods:{
    ...mapActions('subscription', ['findSubOrder','cancelStripe','cancelSubscriptionOrder']),
    async getSub(id){
      this.order = await this.findSubOrder(id);
    },
    async cancelSubscription(id, stripe_id ){
      this.overlay = true
      const response = await this.cancelStripe(stripe_id);
      if (response === true){
        const response_order = await this.cancelSubscriptionOrder();
        if (response_order === true){
          this.overlay = false
          this.sweetAlertProduct('<span>Se cancelo correctamente</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
          await this.$router.push('/checkout')
        }else{
          this.overlay = false
          this.sweetAlertProduct(response_order, 'white', 'success', '#3e3783', 'white', 'top-end')
        }
      }else{
        this.overlay = false
        this.sweetAlertProduct(response, 'white', 'success', '#3e3783', 'white', 'top-end')
      }

    }
  },
  computed:{
    ...mapGetters('auth', ['getUser'])
  }

}
</script>

<style scoped>

</style>
