<template>
  <AppLayout>
      <router-view></router-view>
  </AppLayout>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "App",

  components: {},

  data: () => ({

  }),
  mounted() {
    const theme = this.getDark;
      if (theme === true) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
  },
  computed:{
    ...mapGetters('general', ['getDark'])
  }
};
</script>
<style lang="scss">
@import 'scss/layout';
@import 'scss/login';
</style>
