<template>
  <v-container fluid >
    <v-row class="d-flex justify-center  pa-8 ml-2">
      <v-col cols="12">
        <h1 style="text-align: center">Llene la Información</h1>
      </v-col>
    </v-row>
    <v-form   @submit.prevent="addProducts(product)">
      <v-row class="d-flex pa-8 ml-2 mr-2 layout-form" >
        <v-col
            cols="4"
            sm="4"
            md="4"
        >
          <v-text-field
              label="Nombre"
              placeholder="Nombre"
              v-model="product.name"
              color="input_dashboard"
              style="color: white"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="4"
            sm="4"
            md="4"
        >
          <v-text-field
              label="Codigo de Barras"
              v-model="product.barcode"
              color="input_dashboard"
              placeholder="Codigo de Barras"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="4"
            sm="4"
            md="4"
        >
          <v-text-field
              label="Precio"
              v-model="product.price"
              color="input_dashboard"
              placeholder="Precio"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="4"
            sm="4"
            md="4"
        >
          <v-autocomplete
              v-model="product.category_id"
              :items="categories"
              dense
              no-data-text="Sin Resultados"
              color="input_dashboard"
              filled
              label="Categorias"
          ></v-autocomplete>
        </v-col>
        <v-col  cols="4"
                sm="4"
                md="4">
          <v-autocomplete
              v-model="product.sat_udm_id"
              :items="udms"
              color="input_dashboard"
              no-data-text="Escriba Su unidad de medida"
              :search-input.sync="searchUdm"
              @keyup="SearchUdmF"
              dense
              filled
              label="Unidad de Medida"
          ></v-autocomplete>
        </v-col>
        <v-col  cols="4"
                sm="4"
                md="4">
          <v-autocomplete
              v-model="product.sat_producto_id"
              :items="sat_products"
              no-data-text="Escriba el sat producto"
              color="input_dashboard"
              :search-input.sync="searchSatProductText"
              @keyup="SearchSatProduct"
              dense
              filled
              label="Sat Producto"
          ></v-autocomplete>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="12"
        >
          <v-select
              v-model="taxes_select"
              :items="taxes"
              no-data-text="Sin Resultados"
              color="input_dashboard"
              filled
              chips
              label="Impuestos"
              multiple
          ></v-select>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="12"
        >
          <v-btn
              class="pa-6"
              block
              style="color: white"
              color="primary"
              type="submit"
          >
            Guardar Datos
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <Overlay :overlay="overlay"/>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import Overlay from "../General/Overlay";

export default {
  name: "AddClients",
  data(){
    return{
      overlay:false,
      product:{
        name:'',
        barcode:'',
        price:'',
        sat_udm_id:'',
        sat_producto_id:'',
        category_id:'',
        tax:[]
      },
      taxes_select : [],
      searchUdm:'',
      searchSatProductText:'',
      categories: [],
      taxes:[],
      udms:[],
      sat_products:[]
    }
  },
  components:{
    Overlay
  },
  methods: {
    ...mapActions('sat_request', ['findCategory','findTaxes','findUdmSearch','findSatProductSearch']),
    ...mapActions('products', ['createProducts']),

    async allCategories(){
      this.categories = []
      const response = await this.findCategory();
      for (const a of response){
        this.categories.push({
          text: a.name,
          value: a.id,
        })
      }
    },
    async SearchUdmF(){
      this.udms = []
      const response = await this.findUdmSearch(this.searchUdm);
      for (const a of response){
        this.udms.push({
          text: '('+ a.code +')'+ ' '+a.name,
          value: a.id,
        })
      }
    },
    async SearchSatProduct(){
      this.sat_products = []
      const response = await this.findSatProductSearch(this.searchSatProductText);
      for (const a of response){
        this.sat_products.push({
          text: '('+ a.code +')'+ ' '+a.name,
          value: a.id,
        })
      }
    },
    async allTaxes(){
      this.taxes = []
      const response = await this.findTaxes();
      for (const a of response){
        this.taxes.push({
          text: a.name,
          value: a.id,
        })
      }
    },
    async addProducts(client){
      this.overlay = true
      let taxes = []
      for (const a of  this.taxes_select){
          taxes.push({
            tax_id:a
          })
      }
      client.tax = taxes
      const response = await this.createProducts(client)
      if (response === true){
        this.overlay = false
        this.sweetAlertProduct('<span>Producto creado correctamente</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.$router.push('/productos')
      }else{
        this.overlay = false
        this.sweetAlertProduct(response, 'white', 'error', 'red', 'white', 'top-end')
      }
    }
  },
  async created(){
    await this.allCategories()
    await this.allTaxes()
  },

}
</script>

<style scoped>

</style>
