<template>
  <v-container fluid >
    <v-row class="d-flex justify-center  pa-8 ml-2">
      <v-col cols="12">
        <h1 style="text-align: center">Llene la Información</h1>
      </v-col>
    </v-row>
    <v-form   @submit.prevent="addClients(client)">
      <v-row class="d-flex pa-8 ml-2 mr-2 layout-form" >
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <v-text-field
              label="Nombre"
              placeholder="Nombre"
              v-model="client.full_name"
              color="input_dashboard"
              style="color: white"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <v-text-field
              label="RFC"
              v-model="client.vat"
              color="input_dashboard"
              placeholder="RFC"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="3"
            sm="3"
            md="3"
        >
          <v-text-field
              label="Codigo Postal"
              v-model="client.zip_code"
              color="input_dashboard"
              placeholder="Codigo Postal"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="3"
            sm="3"
            md="3"
        >
          <v-btn
              class="pa-6"
              block
              style="color: white"
              color="primary"
              @click="verifyCode()"
          >
            Validar Codigo Postal
          </v-btn>
        </v-col>
        <v-col
            cols="3"
            sm="3"
            md="3"
        >
          <v-text-field
              label="Ciudad"
              v-model="client.city"
              color="input_dashboard"
              placeholder="Ciudad"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="3"
            sm="3"
            md="3"
        >
          <v-text-field
              label="Estado"
              v-model="client.state"
              color="input_dashboard"
              placeholder="Estado"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="4"
            sm="4"
            md="4"
        >
          <v-autocomplete
              v-model="client.regimen_fiscal_id"
              :items="items"
              dense
              color="input_dashboard"
              filled
              label="Regimen Fiscal"
          ></v-autocomplete>
        </v-col>
        <v-col
            cols="4"
            sm="4"
            md="4"
        >
          <v-text-field
              label="Correo"
              v-model="client.email"
              color="input_dashboard"
              placeholder="Correo"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="4"
            sm="4"
            md="4"
        >
          <v-text-field
              label="Celular"
              v-model="client.phone"
              color="input_dashboard"
              placeholder="Celular"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="12"
        >
          <v-btn
              class="pa-6"
              block
              style="color: white"
              color="primary"
              type="submit"
          >
            Guardar Datos
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <Overlay :overlay="overlay"/>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import Overlay from "../General/Overlay";
export default {
  name: "AddClients",
  data(){
    return{
      overlay:false,
      client:{
        full_name:'',
        vat:'',
        regimen_fiscal_id:'',
        city:'',
        state:'',
        zip_code:'',
        email:'',
        phone:'',
      },
      items: [],
    }
  },
  components:{
    Overlay
  },
  methods: {
    ...mapActions('sat_request', ['findRegimenFiscal']),
    ...mapActions('clients', ['zipCodeVerify','createClients']),
    async RegimenItems(){
      this.items = []
      const response = await this.findRegimenFiscal();
      for (const a of response){
        this.items.push({
          text: a.name,
          value: a.id,
        })
      }
    },
    async verifyCode(){
      const response = await this.zipCodeVerify({code:this.client.zip_code})
      if (response === false){
        this.sweetAlertProduct('Error coloque sus datos', 'white', 'error', 'red', 'white', 'top-end')
      }else{
        this.client.city = response.country_locality_sat_code.name ? response.country_locality_sat_code.name : '';
        this.client.state = response.country_state_sat_code.name ? response.country_state_sat_code.name : '';
      }
    },
    async addClients(client){
      this.overlay = true
      const response = await this.createClients(client);
      if (response === true){
        this.overlay = false
        this.sweetAlertProduct('<span>Cliente creado correctamente</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
       await this.$router.push('/clientes')
      }else{
        this.overlay = false
        this.sweetAlertProduct(response, 'white', 'error', 'red', 'white', 'top-end')
      }

    }
  },
  async created(){
    await this.RegimenItems()
  }
}
</script>

<style scoped>

</style>
