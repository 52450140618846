<template>
  <v-container fluid>

    <v-col cols="3" sm="3" md="3" class="mt-10 mx-2 mx-sm-16">
      <v-text-field v-model="search" label="Buscar"></v-text-field>
    </v-col>
    <v-data-table
        :headers="headersTable"
        :items="invoices"
        :search="search"
        class="elevation-1 mt-10 mx-2 mx-sm-16"
    >

      <template v-slot:top>

        <v-toolbar flat height="0">
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <span @click="routerClient()" style="text-decoration: none;">
                <v-btn
                    text
                    dark
                    style="position: relative; top: -100px; font-size: 18px;"
                >
                 <span class="text-title-tables"> Crear Facturas <i class="far fa-plus-square ml-1"></i></span>
                </v-btn>
              </span>
            </template>
          </v-dialog>

        </v-toolbar>
      </template>
      <template v-slot:item.amount_untaxed="{ item }">
        {{ formatNumber(item.amount_untaxed) }}
      </template>
      <template v-slot:item.amount_tax="{ item }">
        {{ formatNumber(item.amount_tax) }}
      </template>
      <template v-slot:item.amount_total="{ item }">
        {{ formatNumber(item.amount_total) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <span v-if="item.state === 'draft'" style="color: #FFFFFF;text-decoration: none;">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  :to="{name:'ViewInvoiceEdit',params:{id:item.id}}"
                  icon
                  v-bind="attrs"
                  v-on="on"
              >

                <v-icon
                    small
                    class="mr-2"
                    style="color: #eec446; font-size: 25px"

                >
                  fas fa-edit
                </v-icon>

              </v-btn>

            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  @click="stampVerify(item.id)"
                  icon
                  v-bind="attrs"
                  v-on="on"
              >

                <v-icon
                    small
                    class="mr-2"
                    style="color: darkgreen; font-size: 25px"

                >
                  fas fa-stamp
                </v-icon>

              </v-btn>

            </template>
            <span>Timbrar</span>
          </v-tooltip>
        </span>
        <span v-else style="color: #FFFFFF;text-decoration: none;">
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  @click="downloadXml(item.id,item.sat_uuid)"
                  icon
                  v-bind="attrs"
                  v-on="on"
              >

                <v-icon
                    small
                    class="mr-2"
                    style="color: darkgreen; font-size: 25px"


                >
                  fas fa-file-export
                </v-icon>

              </v-btn>

            </template>
            <span>Descargar Xml</span>
          </v-tooltip>
           <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  @click="downloadPdf(item.sat_uuid,item.xml_file_signed_index)"
                  icon
                  v-bind="attrs"
                  v-on="on"
              >

                <v-icon
                    small
                    class="mr-2"
                    style="color: darkred; font-size: 25px"


                >
                  fas fa-file-pdf
                </v-icon>

              </v-btn>

            </template>
            <span>Descargar PDF</span>
          </v-tooltip>
        </span>


      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset</v-btn>
      </template>
    </v-data-table>
    <Overlay :overlay="overlay"/>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Overlay from "../General/Overlay";

export default {
  name: "TableInvoice",
  data() {
    return {
      dialog: false,
      overlay: false,
      dialogEdit: false,
      edit_form: [],
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      headersTable: [
        {text: "id", value: "id"},
        {text: "Referencia", value: "name"},
        {text: "Estado", value: "state"},
        {text: "Total sin impuestos", value: "amount_untaxed"},
        {text: "Impuestos", value: "amount_tax"},
        {text: "Total", value: "amount_total"},
        {text: "Cliente", value: "Customer.full_name"},
        {text: "Acciones", value: "actions", sortable: false},
      ],
      invoices: [],
      show1: false,

    };
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    formTitle() {
      return this.editedIndex === -1 ? "Agregar Clientes" : "Cliente";
    },
  },
  watch: {
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async created() {
    await this.initialize()

  },
  components: {
    Overlay
  },
  methods: {
    ...mapActions('invoices', ['findInvoices', 'stampInvoice', 'downloadXmlFile','downloadXPdfFile']),
    async downloadXml(id,uuid) {
      const send = {
        id,uuid
      }
      await this.downloadXmlFile(send);


    },
    async downloadPdf(uuid,xml) {
      const send = {
        uuid,xml
      }
      await this.downloadXPdfFile(send);


    },
    async stampVerify(id) {
      this.overlay = true
      const send = {
        invoice_id: id
      }
      const response = await this.stampInvoice(send);
      if (response === true) {
        this.overlay = false
        this.sweetAlertProduct('<span>Se timbro correctamente</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.initialize()
      } else {
        this.overlay = false
        this.sweetAlertProduct(`<span>${response.res_stamp}</span>`, 'white', 'error', 'darkred', 'white', 'top-end')
      }
    },
    routerClient() {
      if (!this.getUser.Company) {
        this.$router.push('/company')
      } else {
        this.$router.push('/agregar_factura')
      }

    },
    async initialize() {
      this.invoices = await this.findInvoices();
    },
    closeEdit() {
      this.dialogEdit = false;
      this.client_id = '';
      this.edit_user = {
        full_name: '',
        vat: '',
        regimen_fiscal_id: '',
        regimen_fiscal_text: '',
        city: '',
        state: '',
        zip_code: '',
        email: '',
        phone: '',
      }
    },


    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },

}
</script>

<style scoped>

</style>
