import router from "../router";
import store from '../store';
import {requestFetch} from '../store/config'

export default {
    namespaced: true,
    state: {
        user: {},
        token: '',
        errors: []
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setToken(state, token) {
            state.token = token;
        },
        setErrors(state, error) {
            if (error == 'clear') {
                state.errors = [];
            } else {
                state.errors.push(error);
            }
        }
    },
    actions: {
        //SET ERRORS
        setUser({commit}, user) {
            commit('setUser', user)
        },
        //SET ERRORS
        setToken({commit}, token) {
            commit('setToken', token)
        },
        //SET ERRORS
        setErrors({commit}, error) {
            commit('setErrors', error)
        },

        // LOGIN
        async signin({commit}, user) {
            commit('setErrors', 'clear');

            const response = await requestFetch(`/api/auth/login`, 'POST', user);
            if (response.error) return commit('setErrors', response.error);
            if (response.user.UsersRole.name !== 'user') {
                return commit('setErrors', 'No tiene permisos');
            }
            if (!response.user.Company){
                commit('setUser', response.user);
                commit('setToken', 'Bearer ' + response.access_token);
                return location.replace('/company');
            } else if (!response.user.Company.complete_files){
                commit('setUser', response.user);
                commit('setToken', 'Bearer ' + response.access_token);
                return location.replace('/archivos_compania');

            }else if (!response.user.SubscriptionOrder){
                commit('setUser', response.user);
                commit('setToken', 'Bearer ' + response.access_token);
                return location.replace('/checkout');
            }else{
                commit('setUser', response.user);
                commit('setToken', 'Bearer ' + response.access_token);
                return location.replace('/');
            }
        },
        async createCompany({commit}, data) {
            data.user_id = await store.getters["auth/getUser"].id
            const response = await requestFetch('/api/company/register', 'POST', data);
            if (response.access_token) {
                await store.dispatch('auth/setToken', response.access_token);
                return true
            } else {
                return response.error
            }
        },
        async registerUser({commit}, user) {
            commit('setErrors', 'clear');
            const response = await requestFetch(`/api/auth/register`, 'POST', user);
            if (response.msg) {
                const re = {
                    id:response.msg,
                    status:true
                }
                return re
            } else {
                return response.error
            }
        },
        async registerUserBroker({commit}, user) {
            commit('setErrors', 'clear');
            const response = await requestFetch(`/api/user/broker`, 'POST', user);
            if (response.msg) {
                return true
            } else {
                return response.error
            }
        },
        // VERYFY TOKEN
        async userme({commit}) {
            let response;
            if (!store.getters["auth/getToken"]) {
                commit('setUser', undefined);
            } else {
                response = await requestFetch(`/api/user/me`, 'GET');
                if (response.error) {
                    commit('setUser', undefined);
                    commit('setToken', undefined);
                }
                commit('setUser', response.user);
            }

            return response;

        },

        //LOG OUT
        async logout({commit}) {
            await requestFetch('/api/auth/logout', 'GET');
            // await store.dispatch('auth/userme');
            commit('setUser', undefined);
            commit('setToken', undefined);
            await location.replace('/login');
        },

        // VERYFY EMAIL
        async verifyEmail({commit}, email) {
            commit('setErrors', 'clear');
            const response = await requestFetch(`/api/auth/verifyEmail/${email}`, 'GET');
            if (response.error) return commit('setErrors', response.error);
        },

        // SEND EMAIL RECOVERY PASSWORD
        async sendEmailRecoveryPass({commit}, email) {
            commit('setErrors', 'clear');
            const response = await requestFetch(`/api/auth/forgot_password`, 'POST', email);
            if (response.error) return commit('setErrors', response.error);
        },

        // CHANGE PASSWORD
        async changePassword({commit}, data) {
            commit('setErrors', 'clear');
            const response = await requestFetch(`/api/auth/changePassword`, 'POST', data);
            if (response.error) return commit('setErrors', response.error);
            commit('setUser', undefined);
            commit('setToken', undefined);
        },
        async changePasswordDashboard({commit}, data) {
            const response = await requestFetch(`/api/user/change_password`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return response.error
            }
        },
        async updateBasicInformation({commit}, data) {
            const id = await store.getters["auth/getUser"].id
            const response = await requestFetch(`/api/user/information/${id}`, 'PUT', data);
            if (response.access_token){
                await store.dispatch('auth/setToken', 'Bearer ' +  response.access_token);
                return true
            }else{
                return response.error
            }
        },
    },
    getters: {
        getUser(state) {
            return state.user;
        },
        getToken(state) {
            return state.token;
        },
        getErrors(state) {
            return state.errors;
        }
    }

}
