import router from "../router";
import store from '../store';
import {requestFetch} from'../store/config'
export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        //list clients
        async findProducts({ commit }) {
            const response = await requestFetch('/api/product/company','GET');
            return response.products
        },
        async findProductOne({ commit },id) {
            const response = await requestFetch(`/api/product/one/${id}`,'GET');
            return response.product
        },
        async deleteProducts({ commit },{id}) {
            const response = await requestFetch(`/api/product/delete/${id}`,'DELETE');
            if (response.msg){
                return true
            }else{
                return response.error
            }

        },
        async createProducts({commit}, data) {
            data.company_id = await store.getters["auth/getUser"].Company.id
            const response = await requestFetch('/api/product/add', 'POST', data);
            if (response.msg){
                return true
            }else{
                return response.error
            }
        },
        async updateProductModu({commit}, {data, id}) {
            const response = await requestFetch(`/api/product/${id}`, 'PUT', data);
            if (response.msg){
                return true
            }else{
                return response.error
            }

        },
    },
    getters: {

    }

}
