import router from "../router";
import store from '../store';
import {requestFetch} from'../store/config'
export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        //list clients
        async findClients({ commit }) {
            const response = await requestFetch('/api/customer/company','GET');
            return response.customers
        },
        async findClientOne({ commit },id) {
            const response = await requestFetch(`/api/customer/one/${id}`,'GET');
            return response.client
        },
        async deleteClient({ commit },{id}) {
            const response = await requestFetch(`/api/customer/delete/${id}`,'DELETE');
            if (response.msg){
                return true
            }else{
                return response.error
            }

        },
        async zipCodeVerify({ commit },{code}) {
            const response = await requestFetch(`/api/country/verify/${code}`,'GET');
            if (response.suburb){
                return response.suburb
            }else{
                return false
            }
        },
        async createClients({commit}, data) {
            data.company_id = await store.getters["auth/getUser"].Company.id
            const response = await requestFetch('/api/customer/add', 'POST', data);
            if (response.msg){
                return true
            }else{
                return response.error
            }

        },
        async updateClients({commit}, {data, id}) {
            const response = await requestFetch(`/api/customer/${id}`, 'PUT', data);
            if (response.msg){
                return true
            }else{
                return response.error
            }

        },

    },
    getters: {

    }

}
