import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#3e3783', //#283046
        text_menu: '#3e3783', //#283046
        background:'#ffffff',
        secondary: '#000000',
        input: '#ffffff',
        input_dashboard: '#000000',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark:{
        primary: '#3e3783', //#283046
        text_menu: '#ffffff', //#283046
        background:'#161d31',
        background_light:'#283046',
        secondary: '#ffffff',
        input: '#ffffff',
        input_dashboard: '#ffffff',
      }

    },
  },
});
