<template>
  <div class="margin-top-general">
    <PageHeader
        class="mt-10 mx-2 mx-sm-16"
        :titlePageHeader="titlePageHeader = 'Lista de Productos'"
        :breadcrumbps="breadcrumbps"
    />
    <TableProducts/>

  </div>
</template>

<script>
import PageHeader from "../../../components/Dashboard/PageHeader";
import TableProducts from "../../../components/Products/TableProducts";
export default {
  name: "ListProducts",
  data() {
    return {
      breadcrumbps: [
        {
          text: "Products",
          disabled: false,
          href: "",
        },
        {
          text: "Lista de Productos",
          disabled: false,
          href: "",
        },
      ],
      notes: [],
      company_information:''
    }

  },

  components:{
    PageHeader,
    TableProducts
  }
}
</script>

<style scoped>

</style>
