<template>
  <div class="margin-top-general">
    <PageHeader
        class="mt-10 mx-2 mx-sm-16"
        :titlePageHeader="titlePageHeader = 'Agregar Productos'"
        :breadcrumbps="breadcrumbps"
    />

    <AddProducts/>

  </div>
</template>

<script>
import PageHeader from "../../../components/Dashboard/PageHeader";
import AddProducts from "../../../components/Products/AddProducts";

export default {
  name: "NewProducts",
  data() {
    return {
      breadcrumbps: [
        {
          text: "Productos",
          disabled: false,
          href: "",
        },
        {
          text: "Agregar Productos",
          disabled: false,
          href: "",
        },
      ],
      notes: [],
      company_information:''
    }

  },

  components: {
    PageHeader,
    AddProducts
  }
}
</script>

<style scoped>

</style>
