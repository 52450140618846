<template>
  <div class="margin-top-general">
    <PageHeader
        class="mt-10 mx-2 mx-sm-16"
        :titlePageHeader="titlePageHeader = 'Pago'"
        :breadcrumbps="breadcrumbps"
    />
    <StepCheckout/>
  </div>
</template>

<script>
import PageHeader from "../../../components/Dashboard/PageHeader";
import StepCheckout from "../../../components/Subscription/StepCheckout";
import {mapGetters} from "vuex";
export default {
  name: "Checkout",
  data() {
    return {
      breadcrumbps: [
        {
          text: "Usuario",
          disabled: false,
          href: "",
        },
        {
          text: "Pago",
          disabled: false,
          href: "",
        },
      ],

    }

  },
  components: {
    PageHeader,
    StepCheckout
  },
  created() {
    if (this.getUser.SubscriptionOrder){
      this.$router.push('/')
    }
  },
  computed:{
    ...mapGetters('auth', ['getUser'])
  }
}
</script>

<style scoped>

</style>
