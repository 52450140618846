<template>
  <v-container fluid>
    <v-row class="d-flex   pa-8 ml-2">
      <v-col
          cols="12"
          sm="6"
          md="6"
      >
        <v-form @submit.prevent="updateInformationUser()">
          <v-row class="d-flex pa-8 ml-2 mr-2 layout-form">
            <v-col cols="12">
              <h1 style="text-align: center">Editar Datos</h1>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="6"
            >
              <v-text-field
                  label="Nombre"
                  placeholder="Nombre"
                  v-model="user.first_name"
                  color="input_dashboard"
                  filled
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="6"
            >
              <v-text-field
                  label="Apellidos"
                  placeholder="Apellidos"
                  v-model="user.last_name"
                  color="input_dashboard"
                  filled
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  label="Telefono"
                  placeholder="Telefono"
                  v-model="user.phone"
                  color="input_dashboard"
                  filled
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-btn
                  class="pa-6"
                  block
                  style="color: white"
                  color="primary"
                  type="submit"
              >
                Guardar Datos
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col
          cols="12"
          sm="6"
          md="6"
      >
        <v-form @submit.prevent="changePassword()">
          <v-row class="d-flex pa-8 ml-2 mr-2 layout-form">
            <v-col cols="12">
              <h1 style="text-align: center">Cambiar Contraseña</h1>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  required
                  label="Nueva Contraseña"
                  placeholder="Nueva Contraseña"
                  v-model="passwords.password"
                  color="input_dashboard"
                  type="password"
                  filled
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  required
                  type="password"
                  label="Confirmar contraseña"
                  placeholder="Confirmar contraseña"
                  v-model="passwords.password_confirmation"
                  color="input_dashboard"
                  filled
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-btn
                  class="pa-6"
                  block
                  style="color: white"
                  color="primary"
                  type="submit"
              >
                Guardar Datos
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>

    </v-row>


    <Overlay :overlay="overlay"/>
  </v-container>
</template>

<script>
import Overlay from "../General/Overlay";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "FormProfile",
  data() {
    return {
      overlay: false,
      id: '',
      passwords:{
        password:'',
        password_confirmation:''
      },
      user: {
        first_name: '',
        last_name: '',
        phone: '',
      },
    }
  },
  components: {Overlay},
  methods: {
    ...mapActions('auth', ['changePasswordDashboard','updateBasicInformation']),
    assignCamps() {
      this.id = this.getUser.id
      this.user.first_name = this.getUser.first_name
      this.user.last_name = this.getUser.last_name
      this.user.phone = this.getUser.UserProfile.phone
    },
    async changePassword(){
      this.overlay = true;
      if (!this.passwords.password) {
        this.overlay = false
        this.sweetAlertProduct('Campo Contraseña requerido', 'white', 'error', 'red', 'white', 'top-end')
        return
      }
      if (this.passwords.password !== this.passwords.password_confirmation) {
        this.overlay = false
        this.sweetAlertProduct('Las contraseñas no coinciden', 'white', 'error', 'red', 'white', 'top-end')
        return
      }
      const response = await this.changePasswordDashboard(this.passwords);
      if (response){
        this.overlay = false
        this.sweetAlertProduct('<span>Se edito correctamente las contraseña</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.$router.replace('/')
      }else{
        this.overlay = false
        this.sweetAlertProduct(`<span>${response}</span>`, 'white', 'error', 'red', 'white', 'top-end')
      }
    },
    async updateInformationUser(){
      this.overlay = true;

      const response = await this.updateBasicInformation(this.user);
      if (response){
        this.overlay = false
        this.sweetAlertProduct('<span>Se edito correctamente la información</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.$router.replace('/')
      }else{
        this.overlay = false
        this.sweetAlertProduct(`<span>${response}</span>`, 'white', 'error', 'red', 'white', 'top-end')
      }
    }
  },
  async created() {
    this.assignCamps()

  },
  computed: {
    ...mapGetters('auth', ['getUser'])
  }
}
</script>

<style scoped>

</style>
