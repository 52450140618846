import router from "../router";
import store from '../store';
import {requestFetch} from'../store/config'
export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        async findPackagesSub({ commit }) {
            const response = await requestFetch(`/api/subscription/packages`,'GET');
            return response.packages
        },
        async findSubOrder({ commit }, id) {
            const response = await requestFetch(`/api/subscription/sub_user/${id}`,'GET');
            return response.order
        },
        async createSubscriptionDraft({ commit },data) {
            const response = await requestFetch(`/api/subscription/order`,'POST', {subscription_package_id:data});
            if (response.id){
                const subscription = {
                    id:response.id,
                    reference:response.reference
                }
                return subscription
            }else{
                return response.error
            }
        },
        async updateSubscription({ commit },data) {
            const response = await requestFetch(`/api/subscription/${data.id}`,'PUT', data);
            if (response.access_token){
                await store.dispatch('auth/setToken', 'Bearer ' +  response.access_token);
                return true
            }else{
                return response.error
            }
        },
        async cancelSubscriptionOrder({commit}){
            const response = await requestFetch(`/api/subscription/cancel`,'GET');
            console.log(response)
            if (response.access_token){
                await store.dispatch('auth/setToken', 'Bearer ' +  response.access_token);
                return true
            }else{
                return response.error
            }
        },
        // stripe
        async getCustomer({commit}){
            const id = await store.getters["auth/getUser"].UserBroker.stripe
            const response = await requestFetch(`/api/stripe/customer/${id}`,'GET');
            return response.user
        },
        async getCustomerCard({commit}){
            const id = await store.getters["auth/getUser"].UserBroker.stripe
            const data ={
                customer:id,
                card:'card',
            }
            const response = await requestFetch(`/api/stripe/list_card`,'POST', data);
            return response.cards.data
        },
        async deleteCardCustomer({commit},data){

           const stripe = await store.getters["auth/getUser"].UserBroker.stripe
            const send = {
                customer:stripe,
                card_id:data
            }

            const response = await requestFetch(`/api/stripe/delete_card`,'POST', send);
            if (response.response.deleted){
                return true
            }else{
                return response
            }
        },
        async changeCardPrimary({commit},data){
            const stripe = await store.getters["auth/getUser"].UserBroker.stripe
            const send = {
                customer:stripe,
                card:data
            }
            const response = await requestFetch(`/api/stripe/customer`,'PUT', send);
            if (response.user.id){
                return true
            }else{
                return response
            }
        },
        async newGenerateCard({commit},data){
            const response = await requestFetch(`/api/stripe/card`,'POST', data);

            if (response.card){
                const send = {
                    status:true,
                    id:response.card.id
                }
                return send
            }else{
                return response.error.raw.message            }
        },
        async addCardCustomerSource({commit},data){
            const stripe = await store.getters["auth/getUser"].UserBroker.stripe
            const send = {
                customer:stripe,
                card:data
            }
            const response = await requestFetch(`/api/stripe/source_card`,'POST', send);
            if (response.card){
                return true
            }else{
                return response.error.raw.message
            }
        },
        async cancelStripe({commit},id){
            const response = await requestFetch(`/api/stripe/subscription/cancel/${id}`,'GET');
            if (response.subscription.status === 'canceled'){
                return true
            }else{
                return response.error.raw.message
            }
        },
        async createCustomerStripe({commit},data){
            const response = await requestFetch(`/api/stripe/customer`,'POST', data);
            if (response.customer.id){
                const re = {
                    id:response.customer.id,
                    status:true
                }
                return re
            }else{
                return response.error.raw.message
            }
        },
        async createSubscriptionStripe({ commit },data) {
            const stripe = await store.getters["auth/getUser"].UserBroker.stripe
            data.customer = stripe
            const response = await requestFetch(`/api/stripe/create_subscription`,'POST', data);
            if (response.subscription.status === 'active'){
                const subscription = {
                    id:response.subscription.id,
                    date_initial:response.subscription.current_period_start,
                    date_end:response.subscription.current_period_end,
                }
                return subscription
            }else if (response.subscription.status === 'incomplete'){
                const subscription = {
                    msg: 'Error en el pago'
                }
                return subscription
            }else{
                return response.error.raw.message
            }
        },

    },
    getters: {

    }

}
