var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-col',{staticClass:"mt-10 mx-2 mx-sm-16",attrs:{"cols":"3","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 mt-10 mx-2 mx-sm-16",attrs:{"headers":_vm.headersTable,"items":_vm.invoices,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"0"}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',{staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.routerClient()}}},[_c('v-btn',{staticStyle:{"position":"relative","top":"-100px","font-size":"18px"},attrs:{"text":"","dark":""}},[_c('span',{staticClass:"text-title-tables"},[_vm._v(" Crear Facturas "),_c('i',{staticClass:"far fa-plus-square ml-1"})])])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)]},proxy:true},{key:"item.amount_untaxed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.amount_untaxed))+" ")]}},{key:"item.amount_tax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.amount_tax))+" ")]}},{key:"item.amount_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.amount_total))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.state === 'draft')?_c('span',{staticStyle:{"color":"#FFFFFF","text-decoration":"none"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{name:'ViewInvoiceEdit',params:{id:item.id}},"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"#eec446","font-size":"25px"},attrs:{"small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.stampVerify(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"darkgreen","font-size":"25px"},attrs:{"small":""}},[_vm._v(" fas fa-stamp ")])],1)]}}],null,true)},[_c('span',[_vm._v("Timbrar")])])],1):_c('span',{staticStyle:{"color":"#FFFFFF","text-decoration":"none"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.downloadXml(item.id,item.sat_uuid)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"darkgreen","font-size":"25px"},attrs:{"small":""}},[_vm._v(" fas fa-file-export ")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar Xml")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.downloadPdf(item.sat_uuid,item.xml_file_signed_index)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"darkred","font-size":"25px"},attrs:{"small":""}},[_vm._v(" fas fa-file-pdf ")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar PDF")])])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset")])]},proxy:true}])}),_c('Overlay',{attrs:{"overlay":_vm.overlay}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }