<template>
  <div class="margin-top-general">
    <PageHeader
        class="mt-10 mx-2 mx-sm-16"
        :titlePageHeader="titlePageHeader = 'Lista de clientes'"
        :breadcrumbps="breadcrumbps"
    />
    <TableClients/>

  </div>

</template>

<script>
import PageHeader from "../../../components/Dashboard/PageHeader";
import TableClients from "../../../components/Clients/TableClients";
import {mapGetters} from "vuex";
export default {
  name: "ListClients",
  data() {
    return {
      breadcrumbps: [
        {
          text: "Clientes",
          disabled: false,
          href: "",
        },
        {
          text: "Lista de Clientes",
          disabled: false,
          href: "",
        },
      ],
      notes: [],
      company_information:''
    }

  },

  components:{
    PageHeader,
    TableClients
  },

}
</script>

<style scoped>

</style>
