import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

export const mixins = {
    methods: {
        reformatDate(d) {
            if (d) {
                const da = new Date(d);
                const day = da.getDate();
                const month = da.getMonth() + 1;
                const year = da.getFullYear();
                let dayfinal;
                let monthfinal;
                if (day < 10) {
                    dayfinal = '0' + day
                } else {
                    dayfinal = day
                }
                if (month < 10) {
                    monthfinal = '0' + month
                } else {
                    monthfinal = month
                }
                return dayfinal + '-' + monthfinal + '-' + year;
            }

        },

        sweetAlertProduct(text, text_color, icon, background, iconcolor, position) {
            this.$swal.fire({
                toast: true,
                position: position,
                title: `<small style="color: ${text_color}">${text}</small>`,
                timer: 3000,
                icon: icon,
                background: background,
                showConfirmButton: false,
                iconColor: iconcolor,
                showClass: {
                    popup: 'animate_animated animate_fadeInDown'
                },
                hideClass: {
                    popup: 'animate_animated animate_fadeOutUp'
                }
            })
        },

        formatNumber(a) {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            })
            const h = formatter.format(a).split('$')
            return ' $ ' + ' ' + h[1];
        },

    }
}

Vue.mixin(mixins);
