<template>
  <v-container fluid >
    <v-row class="d-flex justify-center  pa-8 ml-2">
      <v-col cols="12">
        <h1 style="text-align: center">Editar Datos</h1>
      </v-col>
    </v-row>
    <v-row >
      <v-col
          cols="12"
          sm="6"
          md="6"
      >
        <v-form   @submit.prevent="updateInformation()">
          <v-row class="d-flex pa-8 ml-2 mr-2 layout-form" >
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  label="Razón Social"
                  placeholder="Razón Social"
                  v-model="company.business_name"
                  color="input_dashboard"
                  filled
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="6"
            >
              <v-text-field
                  readonly
                  label="RFC"
                  v-model="rfc"
                  color="input_dashboard"
                  placeholder="RFC"
                  filled
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="6"
            >
              <v-text-field
                  label="Codigo Postal"
                  v-model="company.zip_code"
                  color="input_dashboard"
                  placeholder="Codigo Postal"
                  filled
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-autocomplete
                  v-model="company.regimen_fiscal_id"
                  :items="items"
                  dense
                  color="input_dashboard"
                  filled
                  label="Regimen Fiscal"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="getUser.Company.complete_files"
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  label="Sat Contraseña"
                  placeholder="Sat Contraseña"
                  v-model="company.sat_password"
                  color="input_dashboard"
                  type="password"
                  filled
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-btn
                  class="pa-6"
                  block
                  style="color: white"
                  color="primary"
                  type="submit"
              >
                Guardar Datos
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col
          cols="12"
          sm="6"
          md="6"
      >
        <v-form   @submit.prevent="updateInformationFi()">
          <v-row class="d-flex pa-8 ml-2 mr-2 layout-form" >
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <h2 style="text-align: center">Key</h2>
              <v-file-input
                  truncate-length="15"
                  id="mykey"
                  clear-icon=""
                  counter
                  show-size
                  @change="handleImage('key')"
              ></v-file-input>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <h2 style="text-align: center">Cer</h2>
              <v-file-input
                  clear-icon=""
                  truncate-length="15"
                  counter
                  show-size
                  id="mycer"
                  @change="handleImage('cer')"
              ></v-file-input>
            </v-col>

            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-btn
                  class="pa-6"
                  block
                  style="color: white"
                  color="primary"
                  type="submit"
              >
                Guardar Datos
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>


    <Overlay :overlay="overlay"/>
  </v-container>
</template>

<script>
import Overlay from "../General/Overlay";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "FormEditCompany",
  data(){
    return{
      overlay:false,
      rfc:'',
      id:'',
      files:{
        file_key:'',
        file_cert:''
      },
      company:{
        user_id:'',
        sat_password:'',
        regimen_fiscal_id:'',
        business_name:'',
        zip_code:''
      },
      items: []
    }
  },
  components: { Overlay },
  methods:{
    ...mapActions('company',['updateFilesCompany','getCompany','updateCompanyInformation','updateCompanyInformationFiles']),
    ...mapActions('sat_request', ['findRegimenFiscal']),

    async RegimenItems(){
      this.items = []
      const response = await this.findRegimenFiscal();
      for (const a of response){
        this.items.push({
          text: a.name,
          value: a.id,
        })
      }
    },
    async findCompany(){
      const response = await this.getCompany();
      this.company.business_name = response.business_name
      this.rfc = response.rfc
      this.company.regimen_fiscal_id = response.regimen_fiscal_id
      this.company.zip_code = response.zip_code
      this.company.sat_password = response.sat_password
      this.company.user_id = response.user_id
      this.id = response.id
    },
    async handleImage(type) {
      let file = '';
      if (type === 'key'){
        file = document.querySelector('#mykey').files[0]
      }else{
        file = document.querySelector('#mycer').files[0]
      }


      const file_convert = await this.getBase64(file)

      file_convert.onload = (event) =>{
        if (type === 'key'){
          this.files.file_key = event.target.result.split(',')[1]

        }else{
          this.files.file_cert = event.target.result.split(',')[1]
        }
      };
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async updateInformation(){
      this.overlay = true
      const response = await this.updateCompanyInformation({id:this.id,data:this.company});
      if (response){
        this.overlay = false
        this.sweetAlertProduct('<span>Se edito correctamente correcta</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.$router.push('/clientes')
      }else{
        this.overlay = false
        this.sweetAlertProduct(response, 'white', 'error', 'red', 'white', 'top-end')
      }
      console.log(this.company)
    },
    async updateInformationFi(){
      this.overlay = true
      const response = await this.updateCompanyInformationFiles({id:this.id,data:this.files});
      if (response === true){
        this.overlay = false
        this.sweetAlertProduct('<span>Certificados se subieron correctamente</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.$router.push('/clientes')
      }else{
        this.overlay = false
        this.sweetAlertProduct(response, 'white', 'error', 'red', 'white', 'top-end')
      }
      console.log(this.company)
    }

  },
  computed:{
    ...mapGetters('auth', ['getUser'])
  },
  async created(){
    await this.RegimenItems()
    await this.findCompany()
  }
}
</script>

<style scoped>

</style>
