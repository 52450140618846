<template>
  <div>
    <v-col cols="12">
      <h2>Lista tarjetas</h2>
    </v-col>

    <v-row>
       <v-col cols="12">
         <v-btn
             width="100%"
             class="mx-auto"
             color="primary"
             @click="newCardF(true)"
         >
           Nueva Tarjeta
         </v-btn>
      </v-col>
      <v-col cols="6" v-for="c in cards" :key="c.id">
        <v-card
            class="mx-auto"
            max-width="344"
            outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                {{ c.name }}
              </div>
              <v-list-item-title class="text-h5 mb-1">
                ************{{ c.last4 }}

              </v-list-item-title>
              <v-list-item-subtitle>
                {{ c.exp_month }}/{{ c.exp_year }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                style="width: 90px;height: 50px; margin-top: 30px"
            >
              <img v-if="c.brand === 'Visa'" src="../../assets/images/cards/visa.png" style="width: 100%" alt="">
              <img v-else-if="c.brand === 'MasterCard'" src="../../assets/images/cards/mastercard.png"
                   style="width: 100%" alt="">
              <img v-else-if="c.brand === 'Amex'" src="../../assets/images/cards/amex.png" style="width: 100%" alt="">

            </v-list-item-avatar>

          </v-list-item>

          <v-card-actions>
            <v-btn v-if="c.id === customer.default_source"
                   outlined
                   rounded
                   text
                   style="border: 1px solid transparent"
            >
              Principal
            </v-btn>

            <v-btn v-else
                   outlined
                   rounded
                   text
                   @click="changePrimary(c.id)"
            >
              Cambiar Principal
            </v-btn>
            <v-btn
                outlined
                rounded
                text
                style="border: 1px solid transparent; color: darkred"
                @click="deleteCard(c.id)"
            >
              Borrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

    </v-row>

  </div>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ListCard",
  props: {
    cards: Array,
    customer: Object,
    componentCharge: Function,
    overlayFunction: Function,
    newCardF: Function
  },
  methods:{
    ...mapActions('subscription', ['deleteCardCustomer','changeCardPrimary']),
    async deleteCard(card_id){
      this.overlayFunction(true)
      const response = await this.deleteCardCustomer(card_id);
      if (response === true){
        this.sweetAlertProduct('Tarjeta eliminada', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.componentCharge()
        this.overlayFunction(false)
      }else{
        this.sweetAlertProduct(response, 'white', 'error', 'red', 'white', 'top-end')
        await this.componentCharge()
        this.overlayFunction(false)
      }

    },
    async changePrimary(card_id){
      this.overlayFunction(true)
      const response = await this.changeCardPrimary(card_id);
      if (response === true){
        this.sweetAlertProduct('Tarjeta Cambiada', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.componentCharge()
        this.overlayFunction(false)
      }else{
        this.sweetAlertProduct(response, 'white', 'error', 'red', 'white', 'top-end')
        await this.componentCharge()
        this.overlayFunction(false)
      }

    }
  }

}
</script>

<style scoped>

</style>
