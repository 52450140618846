<template>
  <v-container fluid >
    <v-row class="d-flex justify-center  pa-8 ml-2">
      <v-col cols="12">
        <h1 style="text-align: center">Llene la Información</h1>
      </v-col>
    </v-row>
    <v-form   @submit.prevent="addCompany(company)">
      <v-row class="d-flex pa-8 ml-2 mr-2 layout-form" >
        <v-col
            cols="12"
            sm="12"
            md="12"
        >
          <v-text-field
              label="Razón Social"
              placeholder="Razón Social"
              v-model="company.business_name"
              color="input_dashboard"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <v-text-field
              label="RFC"
              v-model="company.rfc"
              color="input_dashboard"
              placeholder="RFC"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <v-text-field
              label="Codigo Postal"
              v-model="company.zip_code"
              color="input_dashboard"
              placeholder="Codigo Postal"
              filled
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="12"
        >
          <v-autocomplete
              v-model="company.regimen_fiscal_id"
              :items="items"
              dense
              color="input_dashboard"
              filled
              label="Regimen Fiscal"
          ></v-autocomplete>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="12"
        >
          <v-btn
              class="pa-6"
              block
              style="color: white"
              color="primary"
              type="submit"
          >
            Guardar Datos
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <Overlay :overlay="overlay"/>
  </v-container>

</template>

<script>

import {mapActions} from "vuex";
import Overlay from "../General/Overlay";
export default {
  name: "FormCompany",
  data(){
    return{
      overlay:false,
      company:{
        rfc:'',
        regimen_fiscal_id:'',
        business_name:'',
        zip_code:''
      },
      items: [],

    }
  },
  components:{
    Overlay
  },
  methods:{
    ...mapActions('sat_request', ['findRegimenFiscal']),
    ...mapActions('company', ['createCompany']),
    async RegimenItems(){
      this.items = []
      const response = await this.findRegimenFiscal();
      for (const a of response){
        this.items.push({
          text: a.name,
          value: a.id,
        })
      }
    },
    async addCompany(company){
      const response = await this.createCompany(company);
      if (response === true){
        this.sweetAlertProduct('<span>Compañia creada correctamente</span>', 'white', 'success', '#3e3783', 'white', 'top-end')
        await this.$router.push('/archivos_compania')
      }else{
        this.sweetAlertProduct(response, 'white', 'error', 'red', 'white', 'top-end')
      }

    }
  },
  async created(){
    await this.RegimenItems()
  }

}
</script>

<style lang="scss">


</style>
