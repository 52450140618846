import router from "../router";
import store from '../store';
import {requestFetch} from'../store/config'
export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        //list clients
        async findRegimenFiscal({ commit }) {
            const response = await requestFetch('/api/sat/regimen','GET');
            return response.tax_regime
        },
        //list clients
        async findCategory({ commit }) {
            const response = await requestFetch('/api/product/categories','GET');
            return response.categories
        },
        async findTaxes({ commit }) {
            const response = await requestFetch('/api/sat/taxes','GET');
            return response.taxes
        },
        async findComprobante({ commit }) {
            const response = await requestFetch('/api/sat/sat_comprobante','GET');
            return response.type_voucher
        },
        async findPeriocidad({ commit }) {
            const response = await requestFetch('/api/sat/periocidad','GET');
            return response.periocidad
        },
        async findUdmSearch({ commit },code) {
            const response = await requestFetch(`/api/sat/udm/${code}`,'GET');
            return response.sat_udm
        },
        async findSatProductSearch({ commit },code) {
            const response = await requestFetch(`/api/sat/sat_product/${code}`,'GET');
            return response.sat_product
        },
        async findSatCfdi({ commit }) {
            const response = await requestFetch('/api/sat/uso_cfdi','GET');
            return response.uso_cfdi
        },
        async findCurrencySearch({ commit },code) {
            const response = await requestFetch(`/api/sat/currency/${code}`,'GET');
            return response.currency
        },
        async findCurrencyAll({ commit }) {
            const response = await requestFetch(`/api/sat/currency`,'GET');
            return response.currency
        },
        async findPayMethod({ commit }) {
            const response = await requestFetch(`/api/sat/pay_method`,'GET');
            return response.pay_method
        },
        async findSatMetodo({ commit },code) {
            const response = await requestFetch(`/api/sat/form_pay/${code}`,'GET');
            return response.form_pay
        },
    },
    getters: {

    }

}
