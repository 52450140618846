import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import ListClients from "../views/Dashboard/Clients/ListClients";
import ListProducts from "../views/Dashboard/Products/ListProducts";
import ViewCompany from "../views/Dashboard/Company/ViewCompany";
import NewClient from "../views/Dashboard/Clients/NewClient";
import NewProducts from "../views/Dashboard/Products/NewProducts";
import ListInvoice from "../views/Dashboard/Invoices/ListInvoice";
import NewInvoice from "../views/Dashboard/Invoices/NewInvoice";
import FilesCompany from "../views/Dashboard/Company/FilesCompany";
import Information from "../views/Dashboard/Company/Information";
import Profile from "../views/Dashboard/Profile/Profile";
import ViewInvoiceEdit from "../views/Dashboard/Invoices/ViewInvoiceEdit";
import Checkout from "../views/Dashboard/Subscription/Checkout";
import Welcome from "../views/Dashboard/Subscription/Welcome";
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login'),
        meta: {
            isLogout: true
        }
    },
    {
        path: '/clientes',
        name: 'ListClients',
        component:ListClients,
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/bienvenido',
        name: 'Welcome',
        component:Welcome,
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/productos',
        name: 'ListProducts',
        component:ListProducts,
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/company',
        name: 'ViewCompany',
        component:ViewCompany,
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/agregar_clientes',
        name: 'NewClient',
        component:NewClient,
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/agregar_productos',
        name: 'NewProducts',
        component:NewProducts,
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/facturas',
        name: 'ListInvoice',
        component:ListInvoice,
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/archivos_compania',
        name: 'FilesCompany',
        component:FilesCompany,
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component:Checkout,
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/agregar_factura',
        name: 'NewInvoice',
        component:NewInvoice,
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/informacion',
        name: 'Information',
        component:Information,
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/perfil',
        name: 'Profile',
        component:Profile,
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/factura/:id',
        name: 'ViewInvoiceEdit',
        component:ViewInvoiceEdit,
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

// let roleRedirect;
// if (localStorage.getItem('user')) {
//   switch (JSON.parse(localStorage.getItem('user')).user_rol_id) {
//     case 1:
//       roleRedirect = '/admin';
//       break;
//     case 2:
//       roleRedirect = '/user';
//       break;
//     case 4:
//       roleRedirect = '/reception'
//   }
// }

router.beforeEach(async (to, from, next) => {

    if (to.meta.isAuthenticated) {

        await store.dispatch('auth/userme');
        if (!store.getters["auth/getUser"]) {
            next('/login')
        } else {
            next();
        }

//       if (to.meta.roleAdmin) {
//         if (JSON.parse(localStorage.getItem('user')).user_rol_id === 1) {
//           next()
//         } else {
//           next(roleRedirect);
//         }
//       } else {
//         next();
//       }


//       if (to.meta.roleUser) {
//         if (JSON.parse(localStorage.getItem('user')).user_rol_id === 2) {
//           next()
//         } else {
//           next(roleRedirect);
//         }
//       } else {
//         next();
//       }

//       if (to.meta.roleReception) {
//         if (JSON.parse(localStorage.getItem('user')).user_rol_id === 4) {
//           next()
//         } else {
//           next(roleRedirect);
//         }
//       } else {
//         next();
//       }
    } else {
        next();
    }


    if (to.meta.isLogout) {
        if (store.getters["auth/getUser"]) {
            next('/');
        } else {
            next();
        }
    } else {
        next();
    }

})

export default router
