<template>
  <v-container fluid>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
            :complete="e1 > 1"
            step="1"
        >
          Elegir paquete
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
            :complete="e1 > 2"
            step="2"
        >
          Metodo de pago
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3">
          Finalizado
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row>
            <v-col v-for="a in packages" :key="a.id" cols="12">
              <v-card
                  :style="color_select === a.id ? 'border: 3px solid #FFDD68; color: white' :null"
                  class="mx-auto"
                  max-width="100%"
                  outlined
              >
                <v-list-item three-line>
                  <v-list-item-content>

                    <v-list-item-title class="text-h5 mb-1" style="text-align: center">
                      {{ a.title }} <span style="text-align: right">{{ formatNumber(a.price) }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle style="text-align: center">
                      {{ a.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-card-actions style="text-align: center">
                  <v-btn
                      :style="color_select === a.id ? 'border: 3px solid #FFDD68; ' :null"
                      @click="checkPackages(a.id, a.price, a.interval_days, a.description, a.title, a.stripe_id)"
                      outlined
                      rounded
                      text
                  >
                    Seleccionar
                  </v-btn>

                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12">
              <div align="right">
                <v-btn v-if="active_continue_one"
                       color="primary"
                       @click="nextStep(2)"
                >
                  Continuar
                </v-btn>
              </div>

            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-row>
            <v-col cols="6">
              <NewCard v-if="list_card" :stateList="status_card" :componentCharge="refreshComponent"
                       :overlayFunction="overlayFunction" :newCardF="changeComponentCard"/>

              <ListCard :cards="cards" :customer="customer_stripe" :componentCharge="refreshComponent"
                        :newCardF="changeComponentCard" :overlayFunction="overlayFunction" v-else/>

            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="12">
                  <h2 style="text-align: center">
                    Detalles de la orden
                  </h2>
                </v-col>
                <v-col cols="6">
                  <h4 style="text-align: center">
                    Producto:
                  </h4>
                </v-col>
                <v-col cols="6">
                  <h4 style="text-align: center">
                    Precio:
                  </h4>
                </v-col>
                <v-col cols="6">
                  <h4 style="text-align: center">
                    {{ order.title }}
                  </h4>
                </v-col>
                <v-col cols="6">
                  <h4 style="text-align: center">
                    {{ formatNumber(order.price) }}
                  </h4>
                </v-col>
                <v-col cols="12">
                  <h4 style="text-align: center">
                    {{ order.description }}
                  </h4>
                </v-col>
                <v-col cols="12">
                  <div align="right">
                    <v-btn
                        v-if="!list_card"
                        color="primary"
                        @click="createOrder()"
                    >
                      Realizar pago
                    </v-btn>

                    <v-btn text @click="e1 = 1">
                      Cancel
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card
              class="mb-12"
              color="grey lighten-1"
              height="200px"
          ></v-card>

          <v-btn
              color="primary"
              @click="e1 = 1"
          >
            Continuar
          </v-btn>

          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <Overlay :overlay="overlay"/>
  </v-container>
</template>

<script>
import ListCard from "../Card/ListCard";
import NewCard from "../Card/NewCard";
import Overlay from "../General/Overlay";
import {mapActions} from "vuex";

export default {
  name: "StepCheckout",
  data() {
    return {
      overlay: false,
      e1: 1,
      packages: [],
      cards: [],
      active_continue_one: false,
      color_select: false,
      customer_stripe: {},
      list_card: false,
      status_card: false,
      response_order: {
        id: '',
        reference: '',
      },
      order: {
        price: '',
        stripe_id: '',
        days: '',
        description: '',
        title: '',
        id: '',
      }
    }
  },
  components: {
    ListCard,
    NewCard,
    Overlay
  },
  methods: {
    ...mapActions('subscription', ['findPackagesSub', 'getCustomer', 'getCustomerCard',
      'createSubscriptionDraft', 'createSubscriptionStripe','updateSubscription']),
    async getPackages() {
      this.packages = await this.findPackagesSub();
    },
    async getCard() {
      this.cards = await this.getCustomerCard();
    },
    async createOrder() {
      this.overlay = true
      const response = await this.createSubscriptionDraft(this.order.id);
      if (response.id) {
        const send = {
          price: this.order.stripe_id,
          reference: response.reference,
        }
        this.response_order.id = response.id
        this.response_order.reference = response.reference
        const response_stripe = await this.createSubscriptionStripe(send)
        if (response_stripe.id){
          const sendUpdate = {
            stripe_subscription_id: response_stripe.id,
            date_initial: response_stripe.date_initial,
            date_end: response_stripe.date_end,
            id: this.response_order.id
          }
          const response_update = await this.updateSubscription(sendUpdate);
          if (response_update === true){
            this.overlay = false
            this.sweetAlertProduct('Subscription creada correctamente', 'white', 'success', '#3e3783', 'white', 'top-end')
            await this.$router.push('/bienvenido')
          }else{
            this.overlay = false
            this.sweetAlertProduct(`<h4>${response_update}</h4>`, 'white', 'error', 'red', 'white', 'top-end')
            return
          }
        }else{
          this.overlay = false
          this.sweetAlertProduct(`<h4>${response_stripe.msg}</h4>`, 'white', 'error', 'red', 'white', 'top-end')
          return
        }
      }else{
        this.overlay = false
        this.sweetAlertProduct(`<h4>${response}</h4>`, 'white', 'error', 'red', 'white', 'top-end')
        return
      }

    },
    async nextStep(step) {
      this.overlay = true
      this.e1 = step
      if (step === 2) {
        await this.findCustomer()
        await this.getCard()
        if (this.customer_stripe.default_source) {
          this.overlay = false
          this.list_card = false
          this.status_card = true
        } else {
          this.overlay = false
          this.list_card = true
          this.status_card = false
        }
      }
    },
    async refreshComponent() {
      await this.findCustomer()
      await this.getCard()
      if (this.customer_stripe.default_source) {
        this.list_card = false
      } else {
        this.list_card = true
      }
    },
    async overlayFunction(state) {
      this.overlay = state

    },
    changeComponentCard(state) {
      this.list_card = state
      this.status_card = state
    },
    async checkPackages(id, price, days, description, title, stripe) {
      this.color_select = id;
      this.order.id = id;
      this.order.stripe_id = stripe;
      this.order.price = price;
      this.order.title = title;
      this.order.description = description;
      this.active_continue_one = true;
    },
    async findCustomer() {
      this.customer_stripe = await this.getCustomer()

    }
  },
  async created() {
    await this.getPackages()

  },
}
</script>

<style scoped>
.theme--dark.v-stepper {
  background: #283046 !important;
}

.v-card__actions {
  align-items: center;
  display: block;
  padding: 8px;
}
</style>
